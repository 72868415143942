import Delta from 'quill-delta';
import EventEmitter from 'events';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { notification } from 'antd';
import { debounce } from 'throttle-debounce';
import { subtitlesDefaults, } from 'api/settings/user-settings';
import { fastCompose } from 'libs/quill-utils';
import { parseVttFormat, serializeToVttFormat } from 'libs/vtt-format-parser';
import { txt } from 'libs/i18n';
import { formatNumber } from 'libs/format-number';
import { PlaybackEvents } from '../MediaPlayer/playback';
import { cleanUpText, getAllMatchIndices, CAPTION_END_SYMBOL, ALL_WHITESPACE_REGEX, TIME_ANCHOR_SYMBOL, } from './text-utils';
import { findBestLineBreakForText } from './line-breaks';
import { asVTT } from './vtt-exporter';
import { getTextLines, missesTrailingSpace } from './caption-utils';
import { CaptionsCache } from './captions-cache';
export const CAPTION_EVENTS = Object.freeze({
    CHANGED: 'changed',
});
dayjs.extend(duration);
export const CAPTION_WARNINGS = Object.freeze({
    TOO_FAST_CRITICAL: 'too-fast-critical',
    FASTER_THAN_OPTIMUM: 'faster-than-optimum',
    TOO_LONG: 'too-long',
    DURATION_SHORT: 'duration-short',
    DURATION_LONG: 'duration-long',
});
const CAPTION_END_REGEX = new RegExp(CAPTION_END_SYMBOL, 'g');
const CAPTION_END_BEFORE_SPACE_REGEX = new RegExp(`${CAPTION_END_SYMBOL}[ \u00a0]`);
export const defaultCaptionParameters = Object.freeze({
    maxLineLength: subtitlesDefaults.maxLineLength,
    triDotString: '... ',
    triDotDuration: 0.3,
    keepInnerLinesStripped: true,
    automaticSpeed: 10,
    speedWarning: subtitlesDefaults.speedWarning,
    speedCriticalWarning: subtitlesDefaults.speedCriticalWarning,
    pauseBetweenCaptions: subtitlesDefaults.pauseBetweenCaptions,
    autofillPauseBetweenCaptions: subtitlesDefaults.autofillPauseBetweenCaptions,
    minDuration: subtitlesDefaults.minDuration,
    maxDuration: subtitlesDefaults.maxDuration,
    enablePresegmentation: false,
    subtitlerMaxLineCount: 2,
    speakerSignPlacement: subtitlesDefaults.speakerSignPlacement,
    speakerSign: '-',
    useSpeakerName: false,
    defaultColor: subtitlesDefaults.speakerColor,
    templateName: '',
    defaultCaptionPosition: subtitlesDefaults.captionPosition,
    defaultBackgroundColor: subtitlesDefaults.backgroundColor,
    defaultBackgroundTransparency: subtitlesDefaults.backgroundTransparency,
    defaultFontSize: subtitlesDefaults.fontSize,
    defaultFontName: subtitlesDefaults.fontName,
    upperCaseAllText: subtitlesDefaults.upperCaseAllText,
    highlightingMode: subtitlesDefaults.highlightingMode,
    unHighlightedColor: subtitlesDefaults.unHighlightedColor,
});
const adjustCaptionLineSpaces = (line, trimEnd) => {
    if (line.length === 0) {
        return;
    }
    const firstSubline = line[0];
    const lastSubline = line[line.length - 1];
    if (trimEnd) {
        lastSubline.text = lastSubline.text.trimEnd();
        if (lastSubline.text === '') {
            // NOTE: If last subline was empty, it is necessary to trim the previous.
            line.pop();
            adjustCaptionLineSpaces(line, trimEnd);
        }
    }
    else if (missesTrailingSpace(lastSubline)) {
        lastSubline.text = `${lastSubline.text} `;
    }
    firstSubline.text = firstSubline.text.trimStart();
    if (firstSubline.text === '') {
        // NOTE: If first subline was empty, it is necessary to trim the next.
        line.shift();
        adjustCaptionLineSpaces(line, trimEnd);
    }
};
// NOTE: Handles updating of caption metadata, provides interface to access them.
export default class Captions {
    constructor(editorController) {
        this.updateRequests = [];
        this.replayedCaption = null;
        this.handleDocumentAligned = (from, to) => {
            // timestamps changed, so we need to recalculate caption ends
            this.requestUpdate(from, to, false);
        };
        this.resolveUpdateRequests = () => {
            if (this.enabled === false)
                return;
            this.editorController.execTextChange({ runAligner: false }, () => {
                while (this.updateRequests.length > 0) {
                    // eslint-disable-next-line no-await-in-loop
                    this.dispatchLongestUpdateRequest();
                }
            });
            this.editorController.triggerSave();
        };
        this.dispatchLongestUpdateRequest = () => {
            let longestRequest = null;
            let longestRequestLength = -Infinity;
            const originalRequestsCount = this.updateRequests.length;
            for (let i = 0; i < originalRequestsCount; i += 1) {
                const [from, to] = this.updateRequests[i];
                if (to - from > longestRequestLength) {
                    longestRequest = [from, to];
                    longestRequestLength = to - from;
                }
            }
            if (longestRequest === null)
                return;
            const [from, to] = longestRequest;
            this.updateCaptions(from, to, true);
            if (originalRequestsCount === this.updateRequests.length) {
                global.logger.error('infinite cycle detected in caption update requests. Resetting.', {
                    requests: JSON.stringify(this.updateRequests),
                });
                this.updateRequests = [];
                throw new Error('failed to update captions');
            }
        };
        this.getCaptionDurationAndSpeed = (lines, begin, end) => {
            const totalLength = this.getCaptionTotalTextLength(lines);
            const captionDuration = end - begin;
            const charsPerSecond = totalLength / captionDuration;
            return {
                totalLength,
                captionDuration,
                charsPerSecond,
            };
        };
        this.getDisplayedSpeakerSign = (index) => {
            const metadata = this.editorController.textMetadata.getMetadataAtIndex(index, 'speakerSign');
            if (metadata !== null) {
                if (metadata.display !== 'hide') {
                    return metadata.content;
                }
            }
            return '';
        };
        this.isAtLeastACaptionFormated = () => {
            return this.editorController.textMetadata.getMetadataAfterIndex('captionFormat', 0).value !== null;
        };
        this.handleReplayCaption = () => {
            const { playback } = this.editorController;
            const captionUnderPlaybackPointer = this.getCaptionAtTimeOrPrevious(playback.time);
            if (captionUnderPlaybackPointer === null)
                return;
            playback.seekTo(captionUnderPlaybackPointer.begin);
            playback.play();
            this.replayedCaption = captionUnderPlaybackPointer;
        };
        this.resetReplayedCaptionToBegin = () => {
            if (this.replayedCaption === null)
                return;
            const { playback } = this.editorController;
            playback.pause();
            playback.seekTo(this.replayedCaption.begin);
            this.replayedCaption = null;
        };
        this.destroy = () => {
            this.editorController.playback.removeEventListener(PlaybackEvents.TimeUpdate, this.handleUpdatePlaybackTime);
        };
        this.colorDefaultSpeakers = () => {
            // NOTE: In workflow only API endpoints are used. Therefore we cannot use the method
            // speakers.getUniqueDocumentSpeakers() that gets speakers from nodes.
            // TODO: Instead of documentSpeakers, only default color of unique speaker should be handled.
            this.editorController.speakers.documentSpeakers.forEach((speaker) => {
                if (this.parameters === null)
                    throw new Error('no parameters loaded');
                if ((speaker.isDefaultColor && speaker.captionColor !== this.parameters.defaultColor)
                    || speaker.captionColor === null) {
                    this.editorController.handleSpeakerColorChange(speaker, this.parameters.defaultColor, true);
                }
            });
        };
        // NOTE: this function returns true if it did some changes.
        this.fixFirstCaptionEndAtParagaphStart = (from, to) => {
            const text = this.editorController.getText(from, to - from);
            const relativeIndex = text.indexOf(`\n${CAPTION_END_SYMBOL}`);
            if (relativeIndex === -1) {
                return false;
            }
            if (from + relativeIndex === this.editorController.getLength() - 2) {
                // NOTE: caption end at paragraph start is allowed if it is the last character in document,
                // because document must end with caption end.
                return false;
            }
            const invalidPatternIndex = from + relativeIndex;
            this.editorController.deleteText(invalidPatternIndex + 1, 1);
            let newPlacementCandidate = invalidPatternIndex;
            while (this.editorController.isNonTranscriptFormat(this.editorController.getLineFormat(newPlacementCandidate))) {
                newPlacementCandidate = this.editorController.getLineStart(newPlacementCandidate - 1) - 1;
                if (newPlacementCandidate === -1) {
                    return true;
                }
            }
            this.editorController.insertText(newPlacementCandidate, CAPTION_END_SYMBOL);
            return true;
        };
        // NOTE: this function returns true if it did some changes.
        this.fixFirstCaptionBeforeSpace = (from, to) => {
            const text = this.editorController.getText(from, to - from);
            const relativeIndex = text.search(CAPTION_END_BEFORE_SPACE_REGEX);
            if (relativeIndex === -1) {
                return false;
            }
            const invalidPatternIndex = from + relativeIndex;
            this.editorController.insertText(invalidPatternIndex + 2, CAPTION_END_SYMBOL);
            this.editorController.deleteText(invalidPatternIndex, 1);
            return true;
        };
        // NOTE: this function returns true if it did some changes.
        this.fixDoubleCaptionEnd = (from, to) => {
            const text = this.editorController.getText(from, to - from);
            const relativeIndex = text.indexOf(`${CAPTION_END_SYMBOL}${CAPTION_END_SYMBOL}`);
            if (relativeIndex === -1) {
                return false;
            }
            const invalidPatternIndex = from + relativeIndex;
            this.editorController.deleteText(invalidPatternIndex, 1);
            return true;
        };
        this.validateCaptionEnds = (from, to) => {
            while (this.fixFirstCaptionEndAtParagaphStart(from, to))
                ;
            while (this.fixFirstCaptionBeforeSpace(from, to))
                ;
            while (this.fixDoubleCaptionEnd(from, to))
                ;
            const length = this.editorController.getLength();
            if (to >= length && this.editorController.getText(length - 2, 1) !== CAPTION_END_SYMBOL) {
                // NOTE: Document should end by caption end symbol. If it does not, create it.
                this.editorController.insertText(this.editorController.getLength() - 1, CAPTION_END_SYMBOL);
            }
        };
        this.handleUpdatePlaybackTime = () => {
            const { playback } = this.editorController;
            // after caption is replayed, playback is seeked to the begin of caption
            if (playback.playing && this.replayedCaption !== null) {
                if (this.replayedCaption.end <= playback.time) {
                    this.resetReplayedCaptionToBegin();
                }
            }
        };
        this.getCaptionTotalTextLength = (lines) => getTextLines(lines).join('').length;
        // eslint-disable-next-line @typescript-eslint/member-ordering
        this.resolveUpdateRequestsDebounce = debounce(40, false, this.resolveUpdateRequests);
        /* finds the optimal line break inside a caption. The caption may span over headings,
          speaker labels and multiple paragraphs, which makes it quite tricky.
        */
        this.suggestLineBreakPos = (from, to) => {
            if (this.parameters === null)
                throw new Error('no parameters loaded');
            const { maxLineLength, subtitlerMaxLineCount } = this.parameters;
            if (subtitlerMaxLineCount === 1) {
                return null; // no line break for single line captions
            }
            if (subtitlerMaxLineCount !== 2) {
                logger.error('unexpected value of subtitlerMaxLineCount', { subtitlerMaxLineCount });
                throw new Error(`unexpected value of subtitlerMaxLineCount ${subtitlerMaxLineCount}`);
            }
            const { text, editorIndices } = this.getTextForSubtitles(from, to);
            let lineBreakIndex = this.lineBreakCache.get(text);
            if (lineBreakIndex === undefined) {
                lineBreakIndex = findBestLineBreakForText(text, this.editorController.language, maxLineLength);
                this.lineBreakCache.set(text, lineBreakIndex);
            }
            if (lineBreakIndex === null)
                return null;
            return editorIndices[lineBreakIndex];
        };
        this.getWarningOnSpeed = (from, to, lines, begin, end) => {
            if (this.parameters === null)
                throw new Error('no parameters loaded');
            const { speedCriticalWarning, speedWarning } = this.parameters;
            const { totalLength, captionDuration, charsPerSecond, } = this.getCaptionDurationAndSpeed(lines, begin, end);
            const charsPerSecondPretty = formatNumber(charsPerSecond, 1, 'ceil');
            // nbsp is used as in DOM regular space in classnames are ignored
            const criticalWarningMessage = `${charsPerSecondPretty}\xa0${txt('charactersPerSec')}`;
            const warningMessage = `${charsPerSecondPretty}\xa0${txt('charactersPerSec')}`;
            const isTooFastCritical = totalLength / captionDuration > speedCriticalWarning + 0.0001;
            const isFasterThanOptimum = totalLength / captionDuration > speedWarning + 0.0001;
            if (isTooFastCritical) {
                return {
                    from,
                    to,
                    type: CAPTION_WARNINGS.TOO_FAST_CRITICAL,
                    message: criticalWarningMessage,
                };
            }
            if (isFasterThanOptimum) {
                return {
                    from,
                    to,
                    type: CAPTION_WARNINGS.FASTER_THAN_OPTIMUM,
                    message: warningMessage,
                };
            }
            return null;
        };
        this.getWarningOnDuration = (from, to, lines, begin, end) => {
            if (this.parameters === null)
                throw new Error('no parameters loaded');
            const { minDuration, maxDuration } = this.parameters;
            const captionDuration = end - begin;
            const durationPretty = formatNumber(captionDuration, 2, 'floor');
            // nbsp is used as in DOM regular space in classnames are ignored
            const minDurationMessage = `${minDuration}\xa0s:\xa0${durationPretty}\xa0s`;
            const maxDurationMessage = `${maxDuration}\xa0s:\xa0${durationPretty}\xa0s`;
            // correction for rounding errors
            const isDurationLong = captionDuration + 0.0001 < minDuration;
            const isDurationShort = captionDuration + 0.0001 > maxDuration;
            if (isDurationLong) {
                return {
                    from,
                    to,
                    type: CAPTION_WARNINGS.DURATION_SHORT,
                    message: minDurationMessage,
                };
            }
            if (isDurationShort) {
                return {
                    from,
                    to,
                    type: CAPTION_WARNINGS.DURATION_LONG,
                    message: maxDurationMessage,
                };
            }
            return null;
        };
        this.getWarningOnLength = (from, to, lines) => {
            const { textMetadata } = this.editorController;
            if (this.parameters === null)
                throw new Error('no parameters loaded');
            const { maxLineLength } = this.parameters;
            const warningOnLines = [];
            getTextLines(lines).forEach((line, lineNumber) => {
                if (line.length > maxLineLength) {
                    const message = String(line.length);
                    const lineBreak = textMetadata.getMetadataAfterIndex('captionLineBreak', from).index;
                    let warningStart = from;
                    let warningEnd = to;
                    if (lineBreak !== null) { // lineBreak is null for single line captions
                        if (lineNumber === 0) {
                            warningEnd = lineBreak + 1;
                        }
                        else if (lineNumber === 1) {
                            warningStart = lineBreak + 1;
                        }
                        else {
                            throw new Error(`unexpected line number: ${lineNumber}`);
                        }
                    }
                    warningOnLines.push({
                        from: warningStart,
                        to: warningEnd,
                        lineNumber,
                        type: CAPTION_WARNINGS.TOO_LONG,
                        message,
                    });
                }
            });
            return warningOnLines;
        };
        this.editorController = editorController;
        this.enabled = false;
        this.parameters = null;
        this.emitter = new EventEmitter();
        this.session = editorController.session;
        this.lineBreakCache = new Map();
        this.captionsCache = new CaptionsCache();
        this.replayedCaption = null;
        this.editorController.playback.addEventListener(PlaybackEvents.TimeUpdate, this.handleUpdatePlaybackTime);
    }
    addEventListener(event, listener) {
        this.emitter.on(event, listener);
    }
    removeEventListener(event, listener) {
        this.emitter.off(event, listener);
    }
    updateCaptionParameters(newParameters) {
        if (this.parameters === null) {
            global.logger.error('cannot set speed warning because parameters are missing');
            return;
        }
        this.parameters = Object.assign(Object.assign({}, this.parameters), newParameters);
        this.captionsCache.reset();
        this.colorDefaultSpeakers();
        this.updateAllMetadata();
        this.editorController.triggerSave();
        this.emitter.emit(CAPTION_EVENTS.CHANGED, { from: 0, to: Infinity });
    }
    loadParameters(trsxParameters) {
        if (trsxParameters === null) {
            // NOTE: if we have a document with caption metadata and then import document
            // without caption metadata, we need to disable caption manager
            this.enabled = false;
            this.parameters = null;
            return;
        }
        if (!this.session.login.hasClaim('editor:subtitleMode')) {
            notification.error({
                message: txt('captionReviewDisabled'),
                placement: 'topRight',
                duration: 10,
            });
            this.enabled = false;
            return;
        }
        this.parameters = trsxParameters;
        this.enabled = true;
        this.captionsCache.reset();
        this.colorDefaultSpeakers();
    }
    getCaptionAtTimeOrPrevious(time) {
        const { textMetadata } = this.editorController;
        if (this.enabled === false)
            return null;
        const textIndex = textMetadata.getTimestampsAtTime(time).to - 1;
        if (textIndex === -1) {
            return null;
        }
        const caption = this.getCaptionAtIndex(textIndex);
        if (caption !== null && caption.begin > time) {
            // Handles edge case, when the timestamps are whitespace at the beginning of a caption,
            // which does not affect the caption begin time.
            return this.getCaptionAtIndex(caption.from - 1);
        }
        return caption;
    }
    getCaptionAtTime(time) {
        const caption = this.getCaptionAtTimeOrPrevious(time);
        if (caption === null || caption.begin > time || caption.end < time) {
            return null;
        }
        return caption;
    }
    getCaptionAtIndex(index, saveToCache = true) {
        const textMetadata = this.editorController.textMetadata;
        const cachedCaption = this.captionsCache.get(index);
        if (cachedCaption !== undefined) {
            return cachedCaption;
        }
        const previousEnd = textMetadata.getMetadataBeforeIndex('captionEnd', index).index;
        const from = previousEnd !== null ? previousEnd + 1 : 0;
        const { begin, firstWordIndex } = this.getNextCaptionBegin(from);
        const captionEnd = textMetadata.getMetadataAfterIndex('captionEnd', from + 1);
        const endIndex = captionEnd.index;
        const end = captionEnd.value;
        let caption = null;
        if (endIndex !== null && end !== null) {
            const lines = this.getCaptionLinesAtRange(from, endIndex);
            const to = endIndex + 1;
            const warningOnSpeed = this.getWarningOnSpeed(from, to, lines, begin, end);
            const warningOnDuration = this.getWarningOnDuration(from, to, lines, begin, end);
            const warningOnLength = this.getWarningOnLength(from, to, lines);
            const timeAnchors = this.getTimeAnchors(from, to);
            const vttFormat = textMetadata.getMetadataAtIndex(endIndex, 'captionFormat');
            const format = vttFormat !== null
                ? parseVttFormat(vttFormat)
                : { line: null, align: null };
            caption = {
                begin,
                end,
                from,
                to,
                lines,
                firstWordIndex,
                warningOnSpeed,
                warningOnDuration,
                warningOnLength,
                timeAnchors,
                format,
            };
        }
        if (saveToCache) {
            this.captionsCache.set(index, caption);
        }
        return caption;
    }
    generateVTT() {
        const captions = [];
        let index = 0;
        const length = this.editorController.getLength();
        while (index < length) {
            const caption = this.getCaptionAtIndex(index, false);
            if (caption === null) {
                index += 1;
                continue;
            }
            captions.push(caption);
            index = caption.to;
        }
        return asVTT(captions);
    }
    requestUpdate(from, to, immediate = false) {
        if (to < from) {
            global.logger.error('attempted to update invalid range', { from, to });
            throw new Error('attempted to update invalid range');
        }
        if (this.enabled === false)
            return;
        this.captionsCache.reset();
        this.updateRequests.push([from, to]);
        if (immediate) {
            void this.resolveUpdateRequests();
        }
        else {
            void this.resolveUpdateRequestsDebounce();
        }
    }
    initialize() {
        if (!this.enabled) {
            // initializing captions that are not enabled happens whenever we load
            // trsx without captions metadata. The class captions is initialized as disabled.
            // This way any requests for subtitles will return no subtitle.
            // We trigger captions update so that captions are updated after switching off
            // subtitle mode.
            this.emitter.emit(CAPTION_EVENTS.CHANGED, { from: 0, to: Infinity });
            return;
        }
        this.initializeLineBreakCache();
        this.updateAllMetadata();
    }
    updateAllMetadata() {
        this.updateCaptions(0, this.editorController.getLength(), true);
    }
    updateCaptions(from, to, updateMetadata) {
        if (!this.enabled)
            return;
        if (from > to)
            return; // NOTE: Ignore invalid range.
        this.validateCaptionEnds(from, to);
        this.captionsCache.reset();
        let delta = new Delta();
        const { textMetadata } = this.editorController;
        let beginIndex = null;
        let updatedIntervalStart = null;
        let end = textMetadata.getMetadataBeforeIndex('captionEnd', from);
        if (end.index === null) {
            // If there is no caption end before "from", the previous
            // caption end is the beginning of the document.
            end.index = -1;
            end.value = 0;
            updatedIntervalStart = 0;
        }
        else {
            updatedIntervalStart = textMetadata.getBeginAtIndex(end.index);
        }
        const updatedRangeFrom = end.index + 1;
        let updatedRangeTo = updatedRangeFrom;
        let isEndOfDocument = false;
        do {
            beginIndex = end.index;
            end = textMetadata.getMetadataAfterIndex('captionEnd', beginIndex + 1);
            if (end.index === null) {
                end = { index: textMetadata.length, value: 1000000 };
                isEndOfDocument = true;
            }
            delta = fastCompose(delta, this.buildSingleCaptionUpdateWithRestore(beginIndex + 1, end.index + 1, updateMetadata));
            updatedRangeTo = end.index + 1;
        } while (beginIndex + 1 < to && !isEndOfDocument);
        const updatedIntervalEnd = end.value;
        this.editorController.updateContents(delta, 'api');
        this.emitter.emit(CAPTION_EVENTS.CHANGED, { from: updatedIntervalStart, to: updatedIntervalEnd });
        this.removeResolvedUpdateRequests(updatedRangeFrom, updatedRangeTo);
    }
    changeCaptionFormat(caption, format) {
        this.editorController.textMetadata.addMetadata('captionFormat', caption.to - 1, serializeToVttFormat(format));
        this.requestUpdate(caption.from, caption.to, true);
    }
    resetCaptionFormat(caption) {
        this.editorController.textMetadata.spliceMetadata('captionFormat', caption.from, caption.to, []);
        this.requestUpdate(caption.from, caption.to, true);
    }
    hasCaptionFormat(caption) {
        const { textMetadata } = this.editorController;
        return textMetadata.getMetadataAtIndex(caption.to - 1, 'captionFormat') !== null;
    }
    // caption line breaks must respect the line break position provided by backend,
    // unless the text was changed. We save the line break positions suggested by backend.
    initializeLineBreakCache() {
        const { textMetadata } = this.editorController;
        let index = 0;
        const length = this.editorController.getLength();
        while (index < length) {
            const caption = this.getCaptionAtIndex(index);
            if (caption === null) {
                index += 1;
                continue;
            }
            index = caption.to;
            const { text, editorIndices } = this.getTextForSubtitles(caption.from, caption.to);
            const lineBreak = textMetadata.getMetadataAfterIndex('captionLineBreak', caption.from).index;
            if (lineBreak === null || lineBreak >= caption.to) {
                this.lineBreakCache.set(text, null);
            }
            else {
                const textIndex = editorIndices.findIndex((x) => x >= lineBreak);
                if (textIndex === -1) { // not found
                    this.lineBreakCache.set(text, null);
                }
                this.lineBreakCache.set(text, textIndex);
            }
        }
    }
    removeResolvedUpdateRequests(changedFrom, changedTo) {
        this.updateRequests = this.updateRequests.filter((requestRange) => {
            const [requestFrom, requestTo] = requestRange;
            if (changedFrom <= requestFrom && changedTo >= requestTo) {
                return false; // This request was resolved - remove it.
            }
            return true; // This request was not resolved - leave it.
        });
    }
    getTextForSubtitles(from, to) {
        if (this.parameters === null)
            throw new Error('no parameters loaded');
        const blockStarts = this.editorController.getBlockStarts(from, to);
        let text = '';
        const editorIndices = []; // translates indices in the caption text to indices in editor
        for (let i = 0; i < blockStarts.length - 1; i += 1) {
            const blockFrom = blockStarts[i];
            const blockTo = blockStarts[i + 1];
            const lineFormat = this.editorController.getLineFormat(blockFrom);
            if (blockTo - blockFrom === 1)
                continue; // ignore empty line
            if (this.editorController.isNonTranscriptFormat(lineFormat))
                continue;
            // NOTE: Add non-breaking space to prevent speaker name splitting.
            const speakerSign = this.getDisplayedSpeakerSign(blockFrom - 1).replaceAll(' ', '\xa0');
            const paragraphContent = this.editorController.getText(blockFrom, blockTo - blockFrom);
            text += `${speakerSign}${paragraphContent}\n`;
            for (let j = 0; j < speakerSign.length; j += 1) {
                editorIndices.push(blockFrom);
            }
            for (let j = 0; j < paragraphContent.length + 1; j += 1) {
                editorIndices.push(blockFrom + j);
            }
        }
        return { text, editorIndices };
    }
    getCaptionLinesAtRange(from, to) {
        const { textMetadata } = this.editorController;
        if (this.parameters === null)
            throw new Error('no parameters loaded');
        const { keepInnerLinesStripped } = this.parameters;
        const lineBreak = textMetadata.getMetadataAfterIndex('captionLineBreak', from).index;
        if (lineBreak === null || lineBreak >= to) {
            const text = this.getCaptionLineText(from, to, true);
            return [text];
        }
        const line1 = this.getCaptionLineText(from, lineBreak + 1, keepInnerLinesStripped);
        const line2 = this.getCaptionLineText(lineBreak + 1, to, true);
        return [line1, line2];
    }
    getTimeAnchors(from, to) {
        const textMetadata = this.editorController.textMetadata;
        const result = [];
        let currentIndex = from;
        while (currentIndex < to) {
            const { index, value } = textMetadata.getMetadataAfterIndex('timeAnchor', currentIndex);
            if (index === null || value === null || index >= to) {
                break;
            }
            result.push({ index, value });
            currentIndex = index + 1;
        }
        return result;
    }
    /*
      returns array [from, to], unless there are line ends between them.
      In such case it includes positions of the line ends in the array between from and to.
      It can be used when you want to process a document range, but you want to handle
      every block separately, if it ranges over more than one block.
    */
    getCaptionLineText(from, to, trimTrailingSpace) {
        var _a;
        if (this.parameters === null)
            throw new Error('no parameters loaded');
        const text = this.editorController.getText(from, to - from);
        const blockStarts = this.editorController.getBlockStarts(from, to);
        const line = [];
        for (let i = 0; i < blockStarts.length - 1; i += 1) {
            const blockFrom = blockStarts[i];
            const blockTo = blockStarts[i + 1];
            const lineFormat = this.editorController.getLineFormat(blockFrom);
            if (this.editorController.isNonTranscriptFormat(lineFormat))
                continue;
            const speakerSign = this.getDisplayedSpeakerSign(blockFrom - 1);
            const cleanedText = cleanUpText(text.substring(blockFrom - from, blockTo - from));
            const speakerColor = (_a = this.editorController.speakers.findLastSpeaker(blockTo)) === null || _a === void 0 ? void 0 : _a.captionColor;
            const subline = {
                index: blockFrom,
                text: speakerSign + cleanedText,
                color: speakerColor !== null && speakerColor !== void 0 ? speakerColor : this.parameters.defaultColor,
            };
            if (subline.text.length > 0) {
                line.push(subline);
            }
        }
        adjustCaptionLineSpaces(line, trimTrailingSpace);
        return line;
    }
    buildSetWarning({ from, to, type, message, }) {
        let delta = new Delta();
        const blockStarts = this.editorController.getBlockStarts(from, to);
        for (let i = 0; i < blockStarts.length - 1; i += 1) {
            const blockFrom = blockStarts[i];
            const blockTo = blockStarts[i + 1];
            const lineFormat = this.editorController.getLineFormat(blockFrom);
            if (this.editorController.isNonTranscriptFormat(lineFormat))
                continue;
            delta = delta.compose(new Delta().retain(blockFrom).retain(blockTo - blockFrom, { warning: `${type} ${message}` }));
        }
        return delta;
    }
    buildResetWarning(from, to) {
        return new Delta().retain(from).retain(to - from, { warning: false });
    }
    getNextCaptionBegin(index) {
        const { textMetadata } = this.editorController;
        let nextTimestamp = textMetadata.getTimestampsAtIndex(index);
        while (nextTimestamp.to && nextTimestamp.to <= this.editorController.getLength()) {
            const format = this.editorController.getLineFormat(nextTimestamp.to - 1);
            const phraseText = this.editorController.getText(nextTimestamp.from, nextTimestamp.to - nextTimestamp.from);
            if (!this.editorController.isNonTranscriptFormat(format)
                && !ALL_WHITESPACE_REGEX.test(phraseText) // ignore phrases with whitespace only
                && !phraseText.includes(TIME_ANCHOR_SYMBOL) // ignore phrases containing only time anchor
                && phraseText !== CAPTION_END_SYMBOL // ignore phrases containing only caption end
                && !phraseText.endsWith('\n\n') // empty line
            ) {
                let firstWordIndex = nextTimestamp.from;
                const text = this.editorController.getText(nextTimestamp.from, nextTimestamp.to - nextTimestamp.from);
                const lastNewLineInsideIndex = text.substring(0, text.length - 1).lastIndexOf('\n');
                if (lastNewLineInsideIndex !== -1) {
                    // handles phrases containing speaker or heading in the beginning
                    // firstWordIndex will be after the newline
                    firstWordIndex += lastNewLineInsideIndex + 1;
                }
                let { begin } = nextTimestamp;
                if (begin === 0) {
                    begin = 0.01; // captions can not begin at 0
                }
                return {
                    begin,
                    firstWordIndex,
                };
            }
            const timestamp = textMetadata.getTimestampsAtIndex(nextTimestamp.to);
            if (timestamp.to <= nextTimestamp.to) {
                break;
            }
            nextTimestamp = timestamp;
        }
        return { begin: Infinity, firstWordIndex: Infinity }; // no next caption found.
    }
    suggestCaptionEndTime(from, to) {
        const textMetadata = this.editorController.textMetadata;
        if (this.parameters === null)
            throw new Error('no parameters loaded');
        const { automaticSpeed, minDuration, pauseBetweenCaptions, autofillPauseBetweenCaptions, } = this.parameters;
        const CAPTION_END_OFFSET = 2;
        // NOTE: Document is empty.
        if (to < CAPTION_END_OFFSET) {
            return 0;
        }
        const timeAnchorValue = textMetadata.getMetadataAtIndex(to - CAPTION_END_OFFSET, 'timeAnchor');
        if (timeAnchorValue !== null) {
            // time anchor as the last character of the captions forces the caption end time
            return timeAnchorValue;
        }
        const { begin: nextBegin } = this.getNextCaptionBegin(to);
        const thisEnd = textMetadata.getEndAtIndex(to - CAPTION_END_OFFSET);
        const { begin: thisBegin } = this.getNextCaptionBegin(from);
        const textLength = this.getCaptionTotalTextLength(this.getCaptionLinesAtRange(from, to));
        const preferredDuration = Math.max(textLength / automaticSpeed, minDuration);
        const maximumPossibleEnd = Math.max(thisBegin, nextBegin - pauseBetweenCaptions);
        if (thisBegin + preferredDuration > maximumPossibleEnd) {
            return maximumPossibleEnd;
        }
        if (thisEnd > maximumPossibleEnd) {
            return maximumPossibleEnd;
        }
        const suggestedCaptionEndTime = Math.max(thisEnd, thisBegin + preferredDuration);
        if (suggestedCaptionEndTime === Infinity) {
            return this.editorController.playback.duration;
        }
        // caption end may be infinity when the last caption is empty
        if (nextBegin - suggestedCaptionEndTime <= autofillPauseBetweenCaptions) {
            return maximumPossibleEnd;
        }
        return suggestedCaptionEndTime;
    }
    /* deals with the situation, when caption end metadata is missing, but the
    caption end symbol is present. This can happen after ctrl + Z */
    buildSingleCaptionUpdateWithRestore(from, to, updateMetadata) {
        let delta = new Delta();
        const text = this.editorController.getText(from, to - from);
        const captionEndIndices = [-1, ...getAllMatchIndices(text, CAPTION_END_REGEX)];
        for (let i = 1; i < captionEndIndices.length; i += 1) {
            const captionFrom = from + captionEndIndices[i - 1] + 1;
            const captionTo = from + captionEndIndices[i] + 1;
            delta = fastCompose(delta, this.buildSingleCaptionUpdate(captionFrom, captionTo, updateMetadata));
        }
        return delta;
    }
    updateSpeakerSigns(from, to) {
        if (this.parameters === null)
            throw new Error('no parameters loaded');
        const { speakerSign, speakerSignPlacement, useSpeakerName } = this.parameters;
        // NOTE: Old trsx had double space within the speakerSign.
        // Now we trim it and add it only when needed.
        const trimmedSpeakerSign = speakerSign.trim();
        let newMetadata = [];
        const blockStarts = this.editorController.getBlockStarts(from, to);
        for (let i = 0; i < blockStarts.length - 1; i += 1) {
            const blockFrom = blockStarts[i];
            const blockTo = blockStarts[i + 1];
            const speaker = this.editorController.speakers.getSpeakerByIndex(blockFrom);
            if (speaker === null) {
                continue;
            }
            const adjustedSpeakerSign = this.shouldAddSpaceToSpeakerSign(blockTo) ? `  ${trimmedSpeakerSign}` : trimmedSpeakerSign;
            const speakerLabel = useSpeakerName ? `${adjustedSpeakerSign}[${speaker.fullName()}] ` : adjustedSpeakerSign;
            const oldMetadata = this.editorController.textMetadata.getMetadataAtIndex(blockTo - 1, 'speakerSign');
            if (oldMetadata === null) {
                newMetadata.push([blockTo - 1, { content: speakerLabel, display: 'default' }]);
            }
            else {
                newMetadata.push([blockTo - 1, Object.assign(Object.assign({}, oldMetadata), { content: speakerLabel })]);
            }
        }
        // NOTE: When captions are set to hide speaker signs, we show only forced ones.
        if (speakerSignPlacement === 'none') {
            newMetadata = newMetadata.filter(([, metadata]) => metadata.display !== 'default');
        }
        this.editorController.textMetadata.spliceMetadata('speakerSign', from - 1, to - 1, newMetadata);
        if (speakerSignPlacement === 'multiSpeakerCaptionsOnly') {
            this.adjustMultiSpeakerSigns(from, to, trimmedSpeakerSign);
        }
    }
    shouldAddSpaceToSpeakerSign(index) {
        const caption = this.getCaptionAtIndex(index);
        if (caption === null) {
            global.logger.error('Caption not found', { captionIndex: index });
            return false;
        }
        if (caption.firstWordIndex === index) {
            return false;
        }
        return caption.lines.some((line) => {
            if (line.length > 1) {
                return line.some((subline, i) => i !== 0 && subline.index === index);
            }
            return false;
        });
    }
    adjustMultiSpeakerSigns(from, to, speakerSign) {
        const signs = this.editorController.textMetadata.getMetadataInRange(from - 1, to - 1, 'speakerSign');
        if (signs.length === 0)
            return;
        const firstSignIndex = signs[0][0];
        const firstSignMetadata = signs[0][1];
        // NOTE: This is a trick to check if the caption starts with something other than speaker sign.
        const leadingText = getTextLines(this.getCaptionLinesAtRange(from, firstSignIndex))
            .join('');
        const startsWithSpeakerSign = leadingText === '';
        if (!startsWithSpeakerSign) {
            let { firstWordIndex } = this.getNextCaptionBegin(from);
            const timeAnchorValue = this.editorController.textMetadata.getMetadataAtIndex(firstWordIndex - 1, 'timeAnchor');
            if (timeAnchorValue !== null) {
                firstWordIndex -= 1;
            }
            if (firstWordIndex > 0) {
                // NOTE: Caption starts with continuation of some utterance.
                // We will add speaker sign and the same forceDisplay information of the following caption.
                this.editorController.textMetadata.addMetadata('speakerSign', firstWordIndex - 1, Object.assign(Object.assign({}, firstSignMetadata), { content: speakerSign }));
            }
        }
        // NOTE: Caption containing only one speaker should not have the sign.
        // We will remove it if it is not forced.
        if (startsWithSpeakerSign && signs.length === 1 && firstSignMetadata.display === 'default') {
            this.editorController.textMetadata.spliceMetadata('speakerSign', from - 1, to, []);
        }
    }
    buildSingleCaptionUpdate(from, to, updateMetadata) {
        if (this.parameters === null)
            throw new Error('no parameters loaded');
        if (updateMetadata) {
            const { textMetadata } = this.editorController;
            this.updateSpeakerSigns(from, to);
            const suggestedLineBreakPos = this.suggestLineBreakPos(from, to);
            const newLineBreakMetadata = suggestedLineBreakPos !== null
                ? [[suggestedLineBreakPos, true]]
                : [];
            textMetadata.spliceMetadata('captionLineBreak', from, to, newLineBreakMetadata);
            const newCaptionEndTime = this.suggestCaptionEndTime(from, to);
            const newCaptionEndTimeRounded = Math.round(newCaptionEndTime * 1000 + 0.001) / 1000;
            textMetadata.addMetadata('captionEnd', to - 1, newCaptionEndTimeRounded);
        }
        return this.buildWarningsUpdate(from, to);
    }
    buildWarningsUpdate(from, to) {
        let delta = new Delta();
        delta = delta.concat(this.buildResetWarning(from, to));
        const caption = this.getCaptionAtIndex(from);
        if (caption === null)
            return new Delta();
        const { warningOnSpeed, warningOnDuration, warningOnLength } = caption;
        if (warningOnSpeed !== null) {
            delta = delta.compose(this.buildSetWarning(warningOnSpeed));
        }
        if (warningOnDuration !== null) {
            delta = delta.compose(this.buildSetWarning(warningOnDuration));
        }
        if (warningOnLength.length > 0) {
            warningOnLength.forEach((warning) => {
                delta = delta.compose(this.buildSetWarning(warning));
            });
        }
        return delta;
    }
}
