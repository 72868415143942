import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { SearchOutlined, RedoOutlined, UndoOutlined, CopyOutlined, UserAddOutlined, ClockCircleOutlined, } from '@ant-design/icons';
import { EraseIcon, PlaybackBindIcon, HeadingOneIcon, HeadingTwoIcon, BackwardIcon, ForwardIcon, SpeakerColorIcon, KeyIcon, CapitalizeIcon, } from 'components/CustomIcons';
import * as clientEnv from 'libs/client-env';
import { App, Divider, Dropdown } from 'antd';
import { txt } from 'libs/i18n';
import { useSession } from 'components/Authenticated';
import CustomizedButton from '../CustomizedButton';
import { useSubModeTutorial } from '../../../subModeTutorial';
import styles from './style.module.less';
const ToolbarButtons = ({ editorController, onKeyPreviewOpen, onTriggerFindAndReplace, isProjectReadOnly, isCaptionMode, onSpeakerColorOpen, }) => {
    const { message } = App.useApp();
    const { session } = useSession();
    const { tutorialGoNext } = useSubModeTutorial();
    const copyDocumentToClipboard = () => {
        editorController.copyDocumentToClipboard();
        void message.success(txt('copied'));
    };
    const capitalizationItems = [
        {
            key: '1',
            label: txt('lowerCaseText'),
        },
        {
            key: '2',
            label: txt('titleCaseText'),
        },
        {
            key: '3',
            label: txt('upperCaseText'),
        },
    ];
    const onCapitalizationClick = ({ key }) => {
        if (key === '1') {
            editorController.keyboardHandler.capitalizeText('lowerCase');
        }
        else if (key === '2') {
            editorController.keyboardHandler.capitalizeText('titleCase');
        }
        else if (key === '3') {
            editorController.keyboardHandler.capitalizeText('upperCase');
        }
    };
    const readOnlyTooltip = isProjectReadOnly ? 'readOnlyProject' : undefined;
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: styles.section, children: [_jsx(CustomizedButton, { tooltipTitle: "assignSpeaker", disabledMessage: readOnlyTooltip, tooltipShortcut: "Shift+Enter", onClick: () => editorController.selectSpeakerFromToolbar(), children: _jsx(UserAddOutlined, {}) }), _jsx(CustomizedButton, { tooltipTitle: "headingOne", disabledMessage: readOnlyTooltip, tooltipShortcut: "Ctrl+1", onClick: () => editorController.insertHeading1(), children: _jsx(HeadingOneIcon, { className: "document-toolbar__icon" }) }), _jsx(CustomizedButton, { tooltipTitle: "headingTwo", disabledMessage: readOnlyTooltip, tooltipShortcut: "Ctrl+2", onClick: () => editorController.sections.insertSection(), children: _jsx(HeadingTwoIcon, {}) }), _jsx(CustomizedButton, { tooltipTitle: "capitals", disabledMessage: readOnlyTooltip, tooltipShortcut: "Ctrl+G", onClick: () => undefined, children: _jsx(Dropdown, { menu: { items: capitalizationItems, onClick: onCapitalizationClick }, trigger: ['hover', 'click'], children: _jsx("div", { children: _jsx(CapitalizeIcon, { className: styles.capitalsIcon }) }) }) }), _jsx(CustomizedButton, { tooltipTitle: "insertTimeStamp", disabledMessage: readOnlyTooltip, tooltipShortcut: "", onClick: () => editorController.insertTimeStampMarker(), children: _jsx(ClockCircleOutlined, { className: "document-toolbar__icon" }) }), _jsx(CustomizedButton, { tooltipTitle: "clearFormat", disabledMessage: readOnlyTooltip, tooltipShortcut: "", onClick: () => editorController.clearFormating(), children: _jsx(EraseIcon, { className: "document-toolbar__icon" }) }), isCaptionMode
                        ? (_jsx(CustomizedButton, { tooltipTitle: "colorSpeakers", disabledMessage: readOnlyTooltip, tooltipShortcut: "", onClick: onSpeakerColorOpen, children: _jsx(SpeakerColorIcon, { className: "document-toolbar__icon" }) })) : null] }), _jsx(Divider, { type: "vertical" }), _jsxs("div", { className: styles.section, children: [isCaptionMode
                        ? (_jsx(CustomizedButton, { tooltipTitle: "insertSubtitleEnd", disabledMessage: readOnlyTooltip, tooltipShortcut: `Ctrl+${txt('bKey')}`, onClick: () => {
                                editorController.insertCaptionEnd();
                                tutorialGoNext();
                            }, children: "\u25C6" }))
                        : null, _jsx(CustomizedButton, { tooltipTitle: "bindWordToPlayback", disabledMessage: readOnlyTooltip, tooltipShortcut: `Ctrl+${txt('qKey')}`, onClick: () => editorController.timeAnchors
                            .insertTimeAnchor(editorController.playback.time), children: _jsx(PlaybackBindIcon, { className: "document-toolbar__icon" }) }), _jsx(CustomizedButton, { tooltipTitle: "startWordSooner", disabledMessage: readOnlyTooltip, tooltipShortcut: `Ctrl+${txt('openBracketKey')}`, onClick: () => editorController.timeAnchors.forceTimestampAndAdjust(-0.1), children: _jsx(ForwardIcon, { className: "document-toolbar__icon" }) }), _jsx(CustomizedButton, { tooltipTitle: "startWordLater", disabledMessage: readOnlyTooltip, tooltipShortcut: `Ctrl+${txt('closeBracketKey')}`, onClick: () => editorController.timeAnchors.forceTimestampAndAdjust(+0.1), children: _jsx(BackwardIcon, { className: "document-toolbar__icon" }) })] }), _jsx(Divider, { type: "vertical" }), _jsxs("div", { className: styles.section, children: [_jsx(CustomizedButton, { tooltipTitle: "copy", tooltipShortcut: "Ctrl+A Ctrl+C", onClick: copyDocumentToClipboard, children: _jsx(CopyOutlined, {}) }), _jsx(CustomizedButton, { tooltipTitle: "ctrlZ", disabledMessage: readOnlyTooltip, tooltipShortcut: `Ctrl+${txt('zKey')}`, onClick: () => editorController.getHistory().undo(), children: _jsx(UndoOutlined, {}) }), _jsx(CustomizedButton, { tooltipTitle: "ctrlY", disabledMessage: readOnlyTooltip, tooltipShortcut: `Ctrl+${txt('yKey')}`, onClick: () => editorController.getHistory().redo(), children: _jsx(RedoOutlined, {}) })] }), _jsx(Divider, { type: "vertical" }), _jsxs("div", { className: styles.section, children: [session.login.hasClaim('keywords:enabled') && clientEnv.getShowKeywords() === true
                        ? (_jsx(CustomizedButton, { tooltipTitle: "keywords", tooltipShortcut: "", onClick: onKeyPreviewOpen, children: _jsx(KeyIcon, { className: "document-toolbar__icon" }) })) : null, _jsx(CustomizedButton, { tooltipTitle: "search", tooltipShortcut: "", onClick: () => onTriggerFindAndReplace('open-find'), children: _jsx(SearchOutlined, {}) })] })] }));
};
export default ToolbarButtons;
