import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import * as ProjectAPI from 'api/project-api';
import { ApiError } from '@newtontechnologies/beey-api-js-client/receivers';
import { Button, Form, App } from 'antd';
import FormModal from 'components/FormModal';
import { useSession } from 'components/Authenticated';
import { useBeeyTutorial } from 'screens/DashboardScreen/beeyTutorial';
import { txt } from 'libs/i18n';
import { BeeyLocale } from 'libs/locales/locale';
import { SubtitlesFormatSchema, subtitlesDefaults } from 'api/settings/user-settings';
import { downloadBlobAsFile } from '../exports';
import { SUBTITLE_LENGTH } from '../CaptionReviewModal';
import DownloadSubtitles from './DownloadSubtitles';
const VTT_TAG_REGEX = /<\/?c\.?[a-z]*>/g; // matches <c.yellow> and </c>
const EMPTY_VTT_CAPTION_REGEX = /\r\n[^\r\n]* --> [^\r\n]*\r\n\r\n/g;
const FORMATED_VTT_REGEX = /STYLE\s*::cue\s*{[^}]*}\r\n\r\n/g;
const SUBTITLE_POSSITION_VTT_REGEX = / align:[^\n\r]* line:[^\r\n]*\r\n/g;
const DownloadSubtitlesModal = ({ visible, onCancel, enqueueProjectUpdate, isCaptionMode, captions, project, onSwitchToCaptionMode, }) => {
    var _a, _b, _c, _d;
    const { session, updateUserSettings } = useSession();
    const savedExportSettings = session.login.user.settings.subtitlesExport;
    const savedSubtitlesSettings = session.login.user.settings.subtitles;
    const { tutorialContinue, tutorialGoNext, retrieveTutorialState } = useBeeyTutorial();
    const [form] = Form.useForm();
    const [additionalInfo, setAdditionalInfo] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const { message } = App.useApp();
    const isTutorialRunning = retrieveTutorialState() === 'running';
    const subtitleLength = (_a = captions.parameters) === null || _a === void 0 ? void 0 : _a.maxLineLength;
    const subsFormat = Form.useWatch('subtitlesFormat', form);
    const highlightingMode = Form.useWatch('highlightingMode', form);
    const unhighlightedColor = (_b = Form.useWatch('unHighlightedColor', form)) !== null && _b !== void 0 ? _b : subtitlesDefaults.unHighlightedColor;
    const selectInitialValue = () => {
        if (session.login.hasClaim('subtitles:all')) {
            return SubtitlesFormatSchema.values[0];
        }
        return SubtitlesFormatSchema.values.find((format) => session.login.hasClaim(`subtitles:${format}`));
    };
    const initialFormat = session.login.hasClaim(`subtitles:${savedExportSettings.subtitlesFormat}`)
        ? savedExportSettings.subtitlesFormat
        : selectInitialValue();
    const initialValues = Object.assign(Object.assign({}, savedExportSettings), { subtitlesFormat: initialFormat, highlightingMode: (_c = savedSubtitlesSettings.highlightingMode) !== null && _c !== void 0 ? _c : 'None', unHighlightedColor: (_d = savedSubtitlesSettings.unHighlightedColor) !== null && _d !== void 0 ? _d : '#ffffff' });
    const createInfoMessage = (subtitlesFormat) => {
        if (subtitlesFormat === 'stl' || subtitlesFormat === 'ttml') {
            return `${txt('format')} ${subtitlesFormat} ${txt('unsupportedFormatting')}`;
        }
        if (subtitlesFormat === 'srt_FB') {
            return txt('noFormatting');
        }
        return null;
    };
    const updateAdditionalInfo = () => {
        var _a;
        if (!isCaptionMode) {
            return;
        }
        setAdditionalInfo(createInfoMessage((_a = form.getFieldValue('subtitlesFormat')) !== null && _a !== void 0 ? _a : initialValues.subtitlesFormat));
    };
    useEffect(() => {
        if (visible === true) {
            tutorialContinue();
            void updateAdditionalInfo();
        }
    }, [visible]);
    const findDifference = (frontendVTT, backendVTT) => {
        let firstDifferenceIndex = Math.min(frontendVTT.length, backendVTT.length);
        for (let i = 0; i < Math.min(frontendVTT.length, backendVTT.length); i += 1) {
            if (frontendVTT[i] !== backendVTT[i]) {
                firstDifferenceIndex = i;
                break;
            }
        }
        return {
            frontend: frontendVTT.substring(firstDifferenceIndex - 50, firstDifferenceIndex + 150),
            backend: backendVTT.substring(firstDifferenceIndex - 50, firstDifferenceIndex + 150),
        };
    };
    const compareVTTwithPreview = async (backendVTTBlob) => {
        const frontendVTT = captions.generateVTT()
            .replace(EMPTY_VTT_CAPTION_REGEX, '');
        const modifiedFrontendVTT = captions.parameters !== null && captions.parameters.upperCaseAllText
            ? frontendVTT.toUpperCase() : frontendVTT;
        const backendVTT = await backendVTTBlob.text();
        const cleanedBackendVTT = backendVTT
            .replaceAll(VTT_TAG_REGEX, '')
            .replaceAll(SUBTITLE_POSSITION_VTT_REGEX, '\r\n')
            .replaceAll(EMPTY_VTT_CAPTION_REGEX, '')
            .replaceAll(FORMATED_VTT_REGEX, '');
        if (modifiedFrontendVTT !== cleanedBackendVTT) {
            global.logger.error('frontend and backend subtitles differ');
            const differenceReport = findDifference(frontendVTT, cleanedBackendVTT);
            global.logger.error('VTT generated by backend differs from preview.', differenceReport);
            return differenceReport.frontend;
        }
        global.logger.info('frontend and backend subtitles match');
        return null;
    };
    const handleSubtitleError = (error) => {
        if (error instanceof ApiError && error.response.status === 422) {
            if (error.errorMessage === 'Subtitle line length must be greater or equal 30.') {
                void message.error(`${txt('minSubtitleLineLength')} ${SUBTITLE_LENGTH.min}`);
            }
            else if (error.errorMessage === 'Subtitle line length must be less or equal 50.') {
                void message.error(`${txt('maxSubtitleLineLength')} ${SUBTITLE_LENGTH.max}`);
            }
            else if (error.errorMessage === 'Subtitle line length must be a positive integer.') {
                void message.error(txt('integerSubtitleLineLength'));
            }
            else if (error.errorMessage.includes('Invalid file format')) {
                void message.error(txt('invalidExportFormat'));
            }
            else {
                void message.error(error.errorMessage);
            }
            global.logger.error('Validation of input value failed.', {}, error);
        }
        else {
            void message.error(txt('subtitleExportMessageError'));
            global.logger.error('Failed to generate subtitles for unhandled reason', {}, error);
            throw error;
        }
    };
    const processSubtitles = async (formValues) => {
        var _a;
        const { name } = project;
        const { maxLineLength, speakerSignPlacement, pauseBetweenCaptions, autofillPauseBetweenCaptionsMs, formatting, upperCaseAllText, } = formValues;
        const language = (_a = await ProjectAPI.fetchProjectLanguage(session.connection, project)) !== null && _a !== void 0 ? _a : BeeyLocale.const('en-US');
        const isRightToLeft = language.isRightToLeft();
        const subtitlesFormat = subsFormat === 'srt_FB' ? 'srt' : subsFormat;
        const subtitlesName = subsFormat === 'srt_FB' ? `${name}.${language.code.replace('-', '_')}` : name;
        try {
            setIsLoading(true);
            const blob = (isCaptionMode && captions.parameters !== null)
                ? await ProjectAPI.downloadSubtitlesFromLabeled(session.connection, project, subtitlesFormat, (formatting === true || formatting === undefined) ? 'AllowAll' : 'DisableAll', upperCaseAllText !== null && upperCaseAllText !== void 0 ? upperCaseAllText : captions.parameters.upperCaseAllText, captions.parameters.keepInnerLinesStripped, isRightToLeft)
                : await ProjectAPI.downloadSubtitles(session.connection, project, subtitlesFormat, maxLineLength, speakerSignPlacement !== null && speakerSignPlacement !== void 0 ? speakerSignPlacement : subtitlesDefaults.speakerSignPlacement, pauseBetweenCaptions !== null && pauseBetweenCaptions !== void 0 ? pauseBetweenCaptions : subtitlesDefaults.pauseBetweenCaptions, autofillPauseBetweenCaptionsMs === undefined
                    ? subtitlesDefaults.autofillPauseBetweenCaptions
                    : autofillPauseBetweenCaptionsMs / 1000, upperCaseAllText !== null && upperCaseAllText !== void 0 ? upperCaseAllText : false, isRightToLeft);
            if (isCaptionMode && subtitlesFormat === 'vtt') {
                const maybeErrorReport = await compareVTTwithPreview(blob);
                if (maybeErrorReport !== null) {
                    // eslint-disable-next-line no-restricted-globals, no-alert
                    if (!confirm(`${txt('subtitlesAndPreviewDiffer')}\n ${maybeErrorReport}`)) {
                        setIsLoading(false);
                        onCancel();
                        return;
                    }
                }
            }
            downloadBlobAsFile(`${subtitlesName}.${subtitlesFormat}`, blob);
            setIsLoading(false);
            updateUserSettings({
                subtitlesExport: {
                    maxLineLength,
                    speakerSignPlacement: speakerSignPlacement !== null && speakerSignPlacement !== void 0 ? speakerSignPlacement : savedExportSettings.speakerSignPlacement,
                    pauseBetweenCaptions: pauseBetweenCaptions !== null && pauseBetweenCaptions !== void 0 ? pauseBetweenCaptions : savedExportSettings.pauseBetweenCaptions,
                    autofillPauseBetweenCaptionsMs: autofillPauseBetweenCaptionsMs !== null && autofillPauseBetweenCaptionsMs !== void 0 ? autofillPauseBetweenCaptionsMs : savedExportSettings.autofillPauseBetweenCaptionsMs,
                    subtitlesFormat: subsFormat,
                    upperCaseAllText: upperCaseAllText !== null && upperCaseAllText !== void 0 ? upperCaseAllText : savedExportSettings.upperCaseAllText,
                    formatting: formatting !== null && formatting !== void 0 ? formatting : savedExportSettings.formatting,
                },
                subtitles: Object.assign(Object.assign({}, savedSubtitlesSettings), { highlightingMode, unHighlightedColor: unhighlightedColor }),
            });
            onCancel();
        }
        catch (error) {
            handleSubtitleError(error);
            setIsLoading(false);
        }
    };
    const handleConfirm = async (formValues) => {
        await enqueueProjectUpdate(async (initialProject) => {
            await processSubtitles(formValues);
            return initialProject;
        });
    };
    const onModalOk = () => {
        form.submit();
        tutorialGoNext();
    };
    return (_jsx(FormModal, { muteShortcuts: ['Tab', 'Shift+Tab'], title: txt('downloadSubtitles'), className: "beey-tutorial-step-format", open: visible, footer: [
            _jsx(Button, { type: "default", onClick: onCancel, disabled: isTutorialRunning, children: txt('cancel') }, "back"),
            _jsx(Button, { type: "primary", htmlType: "submit", onClick: onModalOk, disabled: isLoading, loading: isLoading, children: txt('downloadVerb') }, "submit"),
        ], onCancel: onCancel, width: 560, destroyOnClose: true, closable: !isTutorialRunning, keyboard: !isTutorialRunning, children: _jsx(Form, { form: form, initialValues: initialValues, requiredMark: false, onFinish: handleConfirm, onValuesChange: updateAdditionalInfo, labelAlign: "left", labelWrap: true, labelCol: { span: 12 }, colon: false, children: _jsx(DownloadSubtitles, { isCaptionMode: isCaptionMode, subtitleLength: subtitleLength, onSwitchToCaptionMode: onSwitchToCaptionMode, form: form, additionalInfo: additionalInfo }) }) }));
};
export default DownloadSubtitlesModal;
