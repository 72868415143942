const getClientProp = (propName) => (window.localStorage.getItem(propName));
const getSafeClientProp = (propName, defaultValue) => {
    var _a;
    return ((_a = getClientProp(propName)) !== null && _a !== void 0 ? _a : defaultValue);
};
const setClientProp = (propName, value) => {
    window.localStorage.setItem(propName, value);
};
const clearClientProp = (propName) => {
    window.localStorage.removeItem(propName);
};
export const getUILocale = () => getClientProp('uiLang');
export const setUILocale = (locale) => setClientProp('uiLang', locale);
export const getUserEmail = () => getClientProp('email');
export const setUserEmail = (email) => setClientProp('email', email);
export const getAuthString = () => getClientProp('authString');
export const setAuthString = (token) => setClientProp('authString', token);
export const clearAuthString = () => clearClientProp('authString');
export const getSeenAnnouncementId = () => getClientProp('announcement');
export const setSeenAnnouncementId = (announcementId) => setClientProp('announcement', announcementId);
export const setIsWaveVisible = (isWaveVisible) => setClientProp('isWaveVisible', String(isWaveVisible));
export const getIsWaveVisible = () => getSafeClientProp('isWaveVisible', 'false') === 'true';
export const setIsToolbarHidden = (isToolbarHidden) => setClientProp('isToolbarHidden', String(isToolbarHidden));
export const getIsToolbarHidden = () => getSafeClientProp('isToolbarHidden', 'false') === 'true';
export const getIsMenuCollapsed = () => getSafeClientProp('isMenuCollapsed', 'false') === 'true';
export const setIsMenuCollapsed = (isMenuCollapsed) => setClientProp('isMenuCollapsed', String(isMenuCollapsed));
export const getProjectSidemenuItemsOpen = () => {
    const stringProp = getClientProp('projectSidemenuItemsOpen');
    if (stringProp === null) {
        return ['subtitles', 'translate', 'download', 'notes', 'tags', 'advanced'];
    }
    return JSON.parse(stringProp);
};
export const setMenuItemsOpen = (items) => setClientProp('projectSidemenuItemsOpen', JSON.stringify(items));
export const getIsMainMenuCollapsed = () => {
    if (getClientProp('isMainMenuCollapsed') === null) {
        return null;
    }
    return getClientProp('isMainMenuCollapsed') === 'true';
};
export const setIsMainMenuCollapsed = (isMainMenuCollapsed) => setClientProp('isMainMenuCollapsed', String(isMainMenuCollapsed));
export const getEditorWidth = (defaultWidth = 'auto') => getSafeClientProp('editorWidth', defaultWidth);
export const setEditorWidth = (width) => setClientProp('editorWidth', String(width));
export const getCurrency = (defaultCurrency) => getSafeClientProp('currency', defaultCurrency);
export const setCurrency = (currency) => setClientProp('currency', currency);
export const getVolume = () => getSafeClientProp('volume', '50');
export const setVolume = (volume) => setClientProp('volume', String(volume));
export const getPlayerSpeed = () => getSafeClientProp('speed', '1');
export const setPlayerSpeed = (volume) => setClientProp('speed', String(volume));
export const getIsVideoVisible = () => getSafeClientProp('isVideoVisible', 'true') === 'true';
export const setIsVideoVisible = (isVideoVisible) => setClientProp('isVideoVisible', String(isVideoVisible));
export const getSpeakerSelectPos = (defaultPos) => {
    const stringProp = getClientProp('speakerSelectPos');
    if (stringProp === null) {
        return defaultPos;
    }
    const pos = JSON.parse(stringProp);
    return {
        widthFraction: pos.widthFraction < 0 ? defaultPos.widthFraction : pos.widthFraction,
        heightFraction: pos.heightFraction < 0 ? defaultPos.heightFraction : pos.heightFraction,
    };
};
export const setSpeakerSelectPos = (pos) => (setClientProp('speakerSelectPos', JSON.stringify(pos)));
export const getCookiePermission = () => {
    const stringProp = getClientProp('cookiePermission');
    if (stringProp === null) {
        return null;
    }
    return JSON.parse(stringProp);
};
export const setCookiePermission = (permissionTo) => setClientProp('cookiePermission', JSON.stringify(permissionTo));
export const setShowKeywords = (showKeywords) => setClientProp('showKeywords', String(showKeywords));
export const getShowKeywords = () => getSafeClientProp('showKeywords', 'true') === 'true';
export const getSummaryLang = () => getClientProp('summaryLanguage');
export const setSummaryLang = (language) => setClientProp('summaryLanguage', language);
export const getDisplayedColumns = () => {
    const stringProp = getClientProp('displayedColumns');
    if (stringProp === null) {
        return ['tags', 'length', 'created', 'updated'];
    }
    return JSON.parse(stringProp);
};
export const setDisplayedColumns = (items) => setClientProp('displayedColumns', JSON.stringify(items));
