import { centerHorizontally, splitAtFixedPositionHorizontally, splitAtFixedPositionVertically, } from 'libs/positions';
const MINIMUM_VIDEO_WIDTH = 200;
const MINIMUM_EDITOR_WIDTH = 500;
export const MINIMUM_DOUBLE_EDITOR_WIDTH = 800;
const convertEditorWidthToPixels = (bounds, editorWidth) => {
    if (editorWidth === 'auto') {
        return bounds.width * 0.5;
    }
    if (editorWidth === 'narrow') {
        return 650;
    }
    if (editorWidth === 'medium') {
        return 840;
    }
    return 1000;
};
const divideDocumentAndVideo = (bounds, editorWidth, aspectRatio, isSecondaryEditor) => {
    const widthCoefficient = isSecondaryEditor ? 1.5 : 1;
    const editorWidthPixels = widthCoefficient * convertEditorWidthToPixels(bounds, editorWidth);
    // NOTE: Video is on top of editor for small screens.
    if (bounds.width - editorWidthPixels < MINIMUM_VIDEO_WIDTH
        || editorWidthPixels < MINIMUM_EDITOR_WIDTH) {
        const videoHeight = Math.min(bounds.height * 0.3, bounds.width / aspectRatio);
        const { top: video, bottom: document } = splitAtFixedPositionVertically(bounds, videoHeight);
        return { document, video };
    }
    const { left: document, right: video } = splitAtFixedPositionHorizontally(bounds, editorWidthPixels);
    return { document, video };
};
const computeFullVideoPosition = (bounds, aspectRatio) => {
    if (bounds.width / bounds.height > aspectRatio) {
        const videoWidth = aspectRatio * bounds.height;
        return centerHorizontally(bounds, videoWidth);
    }
    const videoHeight = bounds.width / aspectRatio;
    return {
        display: 'block',
        position: 'fixed',
        height: videoHeight,
        width: bounds.width,
        top: bounds.top,
        left: bounds.left,
    };
};
const computeMinimisedVideoPosition = (bounds, aspectRatio) => {
    const height = 200;
    const width = height * aspectRatio;
    const left = bounds.left + bounds.width - width;
    return {
        display: 'block',
        position: 'fixed',
        height,
        width,
        top: bounds.top,
        left,
    };
};
const computeHiddenVideoPosition = (bounds) => {
    const height = 80;
    const width = 40;
    const left = bounds.left + bounds.width - width;
    const player = {
        display: 'block',
        position: 'fixed',
        top: bounds.top,
        height,
        width,
        left,
    };
    return player;
};
const computeDocumentsLayout = (bounds, editorWidth, isSecondaryEditor) => {
    if (isSecondaryEditor) {
        const { left: secondaryDocument, right: document } = splitAtFixedPositionHorizontally(bounds, bounds.width / 2);
        return {
            document,
            secondaryDocument,
        };
    }
    const noVideoEditorWidthPixels = editorWidth === 'auto'
        ? 840
        : convertEditorWidthToPixels(bounds, editorWidth);
    return {
        document: centerHorizontally(bounds, noVideoEditorWidthPixels),
        secondaryDocument: { display: 'none' },
    };
};
const computeEditorLayoutWithMinimisedVideo = (bounds, editorWidth, aspectRatio, isSecondaryEditor) => {
    const { document, secondaryDocument } = computeDocumentsLayout(bounds, editorWidth, isSecondaryEditor);
    const video = computeMinimisedVideoPosition(bounds, aspectRatio);
    return { document, secondaryDocument, video };
};
const computeEditorLayoutWithHiddenVideo = (bounds, editorWidth, isSecondaryEditor) => {
    const { document, secondaryDocument } = computeDocumentsLayout(bounds, editorWidth, isSecondaryEditor);
    const video = computeHiddenVideoPosition(bounds);
    return { document, secondaryDocument, video };
};
const computeEditorLayoutWithVideo = (bounds, editorWidth, videoAspectRatio, isSecondaryEditor) => {
    const { document: documentBounds, video: videoBounds } = divideDocumentAndVideo(bounds, editorWidth, videoAspectRatio, isSecondaryEditor);
    const video = computeFullVideoPosition(videoBounds, videoAspectRatio);
    if (!isSecondaryEditor) {
        return { document: documentBounds, video, secondaryDocument: { display: 'none' } };
    }
    const { left: secondaryDocument, right: document } = splitAtFixedPositionHorizontally(documentBounds, documentBounds.width / 2);
    return { document, video, secondaryDocument };
};
export const computeMainLayout = (bounds, toolbarHeight) => {
    if (bounds === null) {
        return {
            editor: { display: 'none' },
            toolbar: { display: 'none' },
        };
    }
    const { top: toolbar, bottom: editor } = splitAtFixedPositionVertically(bounds, toolbarHeight);
    return { editor, toolbar };
};
export const computeEditorLayout = (bounds, videoAspectRatio, editorWidth, playerState, showSecondEditor) => {
    if (bounds.display === 'none') {
        return {
            video: { display: 'none' },
            document: { display: 'none' },
            secondaryDocument: { display: 'none' },
        };
    }
    const isSecondaryEditor = showSecondEditor && bounds.width > MINIMUM_DOUBLE_EDITOR_WIDTH;
    const videoDisplayStatus = playerState.video;
    if (videoDisplayStatus === 'no-video' || videoAspectRatio === null) {
        return Object.assign(Object.assign({}, computeDocumentsLayout(bounds, editorWidth, isSecondaryEditor)), { video: { display: 'none' } });
    }
    if (!videoDisplayStatus.isVisible) {
        return computeEditorLayoutWithHiddenVideo(bounds, editorWidth, isSecondaryEditor);
    }
    if (!videoDisplayStatus.isMaximized) {
        return computeEditorLayoutWithMinimisedVideo(bounds, editorWidth, videoAspectRatio, isSecondaryEditor);
    }
    return computeEditorLayoutWithVideo(bounds, editorWidth, videoAspectRatio, isSecondaryEditor);
};
// TODO width selector placement makes no sense and it should have completely new UX.
export const computeWidthSelectorPositions = (bounds) => {
    if (bounds === null) {
        return { display: 'none' };
    }
    return {
        display: 'block',
        position: 'fixed',
        top: bounds.top + 3,
        left: bounds.left + bounds.width - 39,
        height: 30,
        width: 30,
    };
};
