import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Card } from 'antd';
import { CreditCardOutlined, DatabaseOutlined, HistoryOutlined, TeamOutlined, } from '@ant-design/icons';
import { txt } from 'libs/i18n';
import PlanUsage from 'screens/DashboardScreen/pages/AccountPage/CreditInfo/TeamPlan/PlanUsage';
import { useEffect, useState } from 'react';
import { fetchTeamPlan, fetchTeamSubscriptions } from 'api/admin/teams-api';
import { useSession } from 'components/Authenticated';
import { BYTES_IN_GIGABYTE } from 'libs/utils';
import { getAllowedNumberOfMembers } from 'api/team-api';
import styles from '../style.module.less';
const TeamPlanAdmin = ({ teamId, storageStatistics, teamMembersNumber, hasUnlimitedStorage, }) => {
    // NOTE Code around teamPlan will become obsolete soon and will be deleted
    const [subscriptions, setSubscriptions] = useState({
        stripePlan: 'loading',
        marketplacePlan: 'loading',
    });
    const { stripePlan, marketplacePlan } = subscriptions;
    const { session } = useSession();
    const usedStorage = storageStatistics !== 'loading'
        ? (storageStatistics.storagePerCount.reduce((acc, cur) => acc + cur.bytes, 0) / BYTES_IN_GIGABYTE).toFixed(2) : 0;
    const isMarketplaceSubscription = marketplacePlan !== 'loading' && marketplacePlan !== null;
    const isStripeSubscription = stripePlan !== 'loading'
        && stripePlan !== null
        && stripePlan.lookupKey !== 'beey_start';
    const isSubscribed = isStripeSubscription || isMarketplaceSubscription;
    useEffect(() => {
        const fetchPlans = async () => {
            const subs = await fetchTeamSubscriptions(session.connection, teamId);
            if (subs.list.length > 0) {
                setSubscriptions({
                    stripePlan: null,
                    marketplacePlan: subs.list,
                });
            }
            else {
                const plan = await fetchTeamPlan(session.connection, teamId);
                setSubscriptions({
                    stripePlan: plan,
                    marketplacePlan: null,
                });
            }
        };
        void fetchPlans();
    }, []);
    const getMarketplaceSubscriptionValue = (key) => {
        if (isMarketplaceSubscription) {
            const activeSubscription = marketplacePlan.find((sub) => sub.isActive);
            return activeSubscription !== undefined ? activeSubscription[key] : null;
        }
        return null;
    };
    // NOTE Stripe subscription will become obsolete soon, the code will be then simplified
    const getTotalCredit = () => {
        var _a;
        return (_a = getMarketplaceSubscriptionValue('creditInitialAmount')) !== null && _a !== void 0 ? _a : (isStripeSubscription ? stripePlan.fullSubscriptionCredit : 0);
    };
    const getRemainingCredit = () => {
        var _a;
        return (_a = getMarketplaceSubscriptionValue('creditRemainingAmount')) !== null && _a !== void 0 ? _a : (isStripeSubscription ? stripePlan.remainingSubscriptionCredit : 0);
    };
    const getMembersLimit = () => {
        var _a;
        return (_a = getMarketplaceSubscriptionValue('planMembersLimit')) !== null && _a !== void 0 ? _a : (isStripeSubscription ? getAllowedNumberOfMembers(stripePlan.lookupKey) : 0);
    };
    const getStorageLimit = () => {
        var _a;
        return (_a = getMarketplaceSubscriptionValue('planStorageLimit')) !== null && _a !== void 0 ? _a : (isStripeSubscription ? Number(stripePlan.productMetadata.storageLimit) : 10);
    };
    return (_jsxs(Card, { bordered: false, className: styles.card, style: { width: '100%' }, title: (_jsxs(_Fragment, { children: [_jsxs("h2", { children: [_jsx(CreditCardOutlined, {}), "\u00A0", txt('teamPlanUsage')] }), _jsxs("p", { children: [' ', txt('teamPlanUsageLeft'), ":"] })] })), children: [isSubscribed ? (_jsxs(_Fragment, { children: [_jsx(PlanUsage, { icon: _jsx(HistoryOutlined, {}), total: getTotalCredit(), remaining: getRemainingCredit(), unit: "min", decimal: false }), _jsx(PlanUsage, { icon: _jsx(TeamOutlined, {}), total: getMembersLimit(), remaining: getMembersLimit() - teamMembersNumber, unit: txt('fromUsers').toLowerCase(), decimal: false })] })) : null, hasUnlimitedStorage ? (_jsx("p", { style: { textAlign: 'center' }, children: txt('userHasUnlimitedStorage') })) : (_jsx(PlanUsage, { icon: _jsx(DatabaseOutlined, {}), total: getStorageLimit(), remaining: getStorageLimit() - Number(usedStorage), unit: "GB", decimal: true }))] }));
};
export default TeamPlanAdmin;
