import { subtitlesDefaults } from 'api/settings/user-settings';
import { defaultCaptionParameters } from '../../DocumentEditor/captions';
const infinityAsNull = (number) => (number === Infinity ? null : number);
export const subtitlesSettingsFromCaptionParameters = (captionParameters) => ({
    maxLineLength: captionParameters.maxLineLength,
    speedCriticalWarning: infinityAsNull(captionParameters.speedCriticalWarning),
    speedWarning: infinityAsNull(captionParameters.speedWarning),
    automaticSpeed: infinityAsNull(captionParameters.automaticSpeed),
    minDuration: captionParameters.minDuration,
    maxDuration: captionParameters.maxDuration,
    enablePresegmentation: captionParameters.enablePresegmentation,
    spaceAtLineEnd: !captionParameters.keepInnerLinesStripped,
    subtitlerMaxLineCount: captionParameters.subtitlerMaxLineCount,
    speakerSignPlacement: captionParameters.speakerSignPlacement,
    useSpeakerName: captionParameters.useSpeakerName,
    pauseBetweenCaptions: captionParameters.pauseBetweenCaptions,
    autofillPauseBetweenCaptionsMs: captionParameters.autofillPauseBetweenCaptions * 1000,
    defaultColor: captionParameters.defaultColor,
    templateName: captionParameters.templateName,
    defaultCaptionPosition: captionParameters.defaultCaptionPosition,
    defaultBackgroundColor: subtitlesDefaults.backgroundColor,
    defaultBackgroundTransparency: captionParameters.defaultBackgroundTransparency,
    defaultFontSize: captionParameters.defaultFontSize,
    defaultFontName: captionParameters.defaultFontName,
    upperCaseAllText: captionParameters.upperCaseAllText,
    highlightingMode: captionParameters.highlightingMode,
    unHighlightedColor: captionParameters.unHighlightedColor,
});
export const captionParametersFromSubtitlesSettings = (settings) => {
    var _a, _b, _c, _d, _e;
    return ({
        maxLineLength: settings.maxLineLength,
        speedCriticalWarning: (_a = settings.speedCriticalWarning) !== null && _a !== void 0 ? _a : Infinity,
        speedWarning: (_b = settings.speedWarning) !== null && _b !== void 0 ? _b : Infinity,
        automaticSpeed: (_c = settings.automaticSpeed) !== null && _c !== void 0 ? _c : Infinity,
        minDuration: settings.minDuration,
        maxDuration: settings.maxDuration,
        enablePresegmentation: settings.enablePresegmentation,
        keepInnerLinesStripped: !settings.spaceAtLineEnd,
        subtitlerMaxLineCount: settings.subtitlerMaxLineCount,
        speakerSignPlacement: settings.speakerSignPlacement,
        useSpeakerName: settings.useSpeakerName,
        pauseBetweenCaptions: settings.pauseBetweenCaptions,
        autofillPauseBetweenCaptions: settings.autofillPauseBetweenCaptionsMs / 1000,
        speakerSign: defaultCaptionParameters.speakerSign,
        triDotDuration: defaultCaptionParameters.triDotDuration,
        triDotString: defaultCaptionParameters.triDotString,
        defaultColor: settings.defaultColor,
        templateName: settings.templateName,
        defaultCaptionPosition: settings.defaultCaptionPosition,
        defaultBackgroundColor: subtitlesDefaults.backgroundColor,
        defaultBackgroundTransparency: settings.defaultBackgroundTransparency,
        defaultFontSize: settings.defaultFontSize,
        defaultFontName: settings.defaultFontName,
        upperCaseAllText: settings.upperCaseAllText,
        highlightingMode: (_d = settings.highlightingMode) !== null && _d !== void 0 ? _d : 'None',
        unHighlightedColor: (_e = settings.unHighlightedColor) !== null && _e !== void 0 ? _e : subtitlesDefaults.unHighlightedColor,
    });
};
