import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { App, Dropdown } from 'antd';
import { txt } from 'libs/i18n';
import { PlaySquareOutlined, UserAddOutlined, SnippetsOutlined, FontSizeOutlined, UserDeleteOutlined, ReadOutlined, ScissorOutlined, CopyOutlined, FireOutlined, } from '@ant-design/icons';
import { HeadingOneIcon, HeadingTwoIcon, EraseIcon } from 'components/CustomIcons';
import ContextMenuLabel from './ContextMenuLabel';
import ContextMenuHelp from './ContextMenuHelp';
import styles from './style.module.less';
import BurnSubtitlesModal from '../ProjectMenuPanel/BurnSubtitlesModal';
const ContextMenu = ({ children, editorController, isCaptionMode, disabled, project, enqueueProjectUpdate, }) => {
    var _a, _b;
    const [contextMenuVisible, setContextMenuVisible] = useState(false);
    const [helpVisible, setHelpVisible] = useState(false);
    const [burnSubtitlesModalVisible, setBurnSubtitlesModalVisible] = useState(false);
    const [menuTrigger, setMenuTrigger] = useState({ trigger: 'phrase', selected: false, menuContent: [] });
    const { message } = App.useApp();
    const handleContextMenuVisible = (visible) => {
        setContextMenuVisible(visible);
    };
    const removeSpeaker = () => {
        if (menuTrigger.trigger === 'speaker') {
            editorController.speakers.removeSpeakerOnIndex(menuTrigger.speakerIndex, 'user');
        }
    };
    const cutText = () => {
        // NOTE: There is currently no ideal substitution for deprecated execCommand.
        document.execCommand('cut');
        void message.success(txt('cutSuccess'));
    };
    const copyToClipboard = () => {
        // NOTE: There is currently no ideal substitution of deprecated execCommand.
        document.execCommand('copy');
        void message.success(txt('copiedTitle'));
    };
    const items = [
        {
            key: txt('findAndReplaceWord'),
            icon: _jsx(ReadOutlined, {}),
            label: (_jsx(ContextMenuLabel, { label: txt('findAndReplaceWord'), shortcut: "Ctrl + H" })),
            onClick: () => editorController.onTriggerFindAndReplace('open-find-replace'),
        },
        {
            type: 'divider',
            className: styles.divider,
        },
        {
            key: txt('playFromCursor'),
            icon: _jsx(PlaySquareOutlined, {}),
            label: (_jsx(ContextMenuLabel, { label: txt('playFromCursor'), shortcut: "Shift + Tab" })),
            onClick: () => editorController.playback
                .playFromTime(editorController.caretTimeRef.current),
        },
        {
            type: 'divider',
            className: styles.divider,
        },
        {
            key: txt('changeSpeaker'),
            icon: _jsx(UserAddOutlined, {}),
            label: (_jsx(ContextMenuLabel, { label: menuTrigger.trigger === 'speaker' ? txt('changeSpeaker') : txt('assignSpeaker'), shortcut: "Shift + Enter" })),
            onClick: () => editorController.selectSpeakerFromToolbar(),
        },
        {
            key: txt('deleteSpeaker'),
            icon: _jsx(UserDeleteOutlined, {}),
            label: (_jsx(ContextMenuLabel, { label: txt('deleteSpeaker').slice(0, -1) })),
            onClick: removeSpeaker,
        },
        {
            type: 'divider',
            className: styles.divider,
        },
        {
            key: txt('cutLabel'),
            icon: _jsx(ScissorOutlined, {}),
            label: (_jsx(ContextMenuLabel, { label: txt('cutLabel'), shortcut: "Ctrl + X" })),
            disabled: !menuTrigger.selected,
            onClick: cutText,
        },
        {
            key: txt('copyLabel'),
            icon: _jsx(CopyOutlined, {}),
            label: (_jsx(ContextMenuLabel, { label: txt('copyLabel'), shortcut: "Ctrl + C" })),
            disabled: !menuTrigger.selected,
            onClick: copyToClipboard,
        },
        {
            key: txt('pasteLabel'),
            icon: _jsx(SnippetsOutlined, {}),
            label: (_jsx(ContextMenuLabel, { label: txt('pasteLabel'), shortcut: "Ctrl + V" })),
            onClick: () => setHelpVisible(true),
        },
        {
            type: 'divider',
            className: styles.divider,
        },
        {
            key: txt('burnSubtitlesInVideo'),
            icon: _jsx(FireOutlined, {}),
            label: (_jsx(ContextMenuLabel, { label: txt('burnSubtitlesInVideo') })),
            onClick: () => setBurnSubtitlesModalVisible(true),
        },
        {
            type: 'divider',
            className: styles.divider,
        },
        {
            key: txt('insertSubtitleEnd'),
            icon: '◆',
            className: styles.captionEndItem,
            label: (_jsx(ContextMenuLabel, { label: txt('insertSubtitleEnd').slice(0, -1), shortcut: `(Ctrl+${txt('bKey')})` })),
            onClick: () => editorController.insertCaptionEnd(),
        },
        {
            type: 'divider',
            className: styles.divider,
        },
        {
            key: txt('editFormat'),
            icon: _jsx(FontSizeOutlined, {}),
            label: txt('editFormat'),
            className: styles.formatTextItem,
            children: [
                {
                    key: txt('headingOne'),
                    icon: _jsx(HeadingOneIcon, {}),
                    label: (_jsx(ContextMenuLabel, { label: txt('headingOne'), shortcut: "Ctrl + 1" })),
                    onClick: () => editorController.insertHeading1(),
                },
                {
                    key: txt('headingTwo'),
                    icon: _jsx(HeadingTwoIcon, {}),
                    label: (_jsx(ContextMenuLabel, { label: txt('headingTwo'), shortcut: "Ctrl + 2" })),
                    onClick: () => editorController.sections.insertSection(),
                },
                {
                    key: txt('clearFormat'),
                    icon: _jsx(EraseIcon, {}),
                    label: (_jsx(ContextMenuLabel, { label: txt('clearFormat') })),
                    onClick: () => editorController.clearFormating(),
                },
            ],
        },
    ];
    const retrieveTrigger = (target) => {
        var _a, _b, _c;
        const cursorIndex = (_a = editorController.getSelection()) === null || _a === void 0 ? void 0 : _a.index;
        const selected = editorController.getSelectedText() !== '';
        const isSpeaker = (_c = (_b = target.parentElement) === null || _b === void 0 ? void 0 : _b.classList.contains('speaker')) !== null && _c !== void 0 ? _c : false;
        const isSummary = cursorIndex !== undefined
            && editorController.getLineFormat(cursorIndex).summary !== undefined;
        const isSectionName = target.closest('H2') !== null;
        if (isSpeaker && cursorIndex !== undefined) {
            return {
                trigger: 'speaker',
                speakerIndex: cursorIndex - 1,
                selected,
                menuContent: [txt('changeSpeaker'), txt('deleteSpeaker')],
            };
        }
        if (isSectionName && editorController.sectionNamesList !== null) {
            return {
                trigger: 'section',
                selected,
                menuContent: [txt('editFormat')],
            };
        }
        if (isSummary) {
            return {
                trigger: 'summary',
                selected,
                menuContent: [txt('copyLabel'), txt('pasteLabel'), txt('cutLabel')],
            };
        }
        const phraseExcludedActions = [txt('deleteSpeaker'), txt('insertSubtitleEnd')];
        return {
            trigger: 'phrase',
            selected,
            menuContent: items.filter((item) => (item === null || item === void 0 ? void 0 : item.key) !== undefined
                && !phraseExcludedActions.includes(String(item.key))).map((item) => item === null || item === void 0 ? void 0 : item.key),
        };
    };
    const generateMenuContent = (contextMenuTrigger, showCaptionEnd) => {
        return items.filter((item) => {
            const itemKey = String(item === null || item === void 0 ? void 0 : item.key);
            const isInMenuContent = contextMenuTrigger.menuContent.includes(itemKey);
            if (contextMenuTrigger.trigger === 'phrase') {
                const isInsertSubtitleEnd = showCaptionEnd && itemKey === txt('insertSubtitleEnd');
                const isDivider = item !== null && 'type' in item && item.type === 'divider';
                return isDivider || isInsertSubtitleEnd || isInMenuContent;
            }
            return isInMenuContent;
        });
    };
    const handlePointerUp = (event) => {
        const target = event.target;
        if (target !== null && event.button === 2 && event.altKey) {
            setMenuTrigger(() => retrieveTrigger(target));
        }
    };
    return (_jsxs("div", { children: [_jsx(ContextMenuHelp, { visible: helpVisible, onClose: () => setHelpVisible(false) }), _jsx(Dropdown, { menu: {
                    items: generateMenuContent(menuTrigger, isCaptionMode),
                    onClick: () => handleContextMenuVisible(false),
                }, open: contextMenuVisible && !disabled, trigger: ['contextMenu'], onOpenChange: handleContextMenuVisible, forceRender: true, overlayClassName: styles.contextMenu, children: _jsx("div", { onPointerUpCapture: handlePointerUp, children: children }) }), _jsx(BurnSubtitlesModal, { visible: burnSubtitlesModalVisible, onCancel: () => setBurnSubtitlesModalVisible(false), enqueueProjectUpdate: enqueueProjectUpdate, isCaptionMode: isCaptionMode, captionsParameters: editorController.captions.parameters, isRightToLeft: (_b = (_a = editorController.getLanguage()) === null || _a === void 0 ? void 0 : _a.isRightToLeft()) !== null && _b !== void 0 ? _b : false, selectedTextTimestamps: editorController.getSelectionTimeRange(), project: project })] }));
};
export default ContextMenu;
