import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Alert, Form, InputNumber, Select, Switch, } from 'antd';
import { txt } from 'libs/i18n';
import { SubtitlesFormatSchema } from 'api/settings/user-settings';
import { useSession } from 'components/Authenticated';
import AlertWithButton from 'components/AlertWithButton';
import { SUBTITLE_LENGTH } from '../../CaptionReviewModal';
import styles from './style.module.less';
const NO_FORMATTING_SUPPORT_FORMATS = ['srt_FB', 'stl', 'ttml'];
const DownloadSubtitles = ({ isCaptionMode, subtitleLength, form, additionalInfo, onSwitchToCaptionMode, }) => {
    const { session } = useSession();
    const allSubtitlesAllowed = session.login.hasClaim('subtitles:all');
    const subtitlesFormat = Form.useWatch('subtitlesFormat', form);
    const handleChangeFormat = (format) => {
        form.setFieldValue('formatting', !NO_FORMATTING_SUPPORT_FORMATS.includes(format));
    };
    return (_jsxs(_Fragment, { children: [_jsx(Form.Item, { label: `${txt(isCaptionMode ? 'selectedSubtitleLength' : 'chooseLineLength')}:`, children: isCaptionMode ? (_jsx("span", { children: `${subtitleLength !== null && subtitleLength !== void 0 ? subtitleLength : -1} ${txt('charactersPerLine')}` })) : (_jsxs(_Fragment, { children: [_jsx(Form.Item, { name: "maxLineLength", label: `${txt('chooseLineLength')}:`, noStyle: true, required: true, rules: [
                                {
                                    required: true,
                                    type: 'number',
                                    min: SUBTITLE_LENGTH.min,
                                    max: SUBTITLE_LENGTH.max,
                                    message: `${txt('range')} ${SUBTITLE_LENGTH.min} - ${SUBTITLE_LENGTH.max}`,
                                },
                            ], children: _jsx(InputNumber, { type: "number", autoFocus: true }) }), ' ', txt('charactersPerLine')] })) }), _jsx(Form.Item, { name: "subtitlesFormat", label: txt('chooseSubtFormat'), children: _jsx(Select, { autoFocus: isCaptionMode, onChange: (format) => handleChangeFormat(format), children: allSubtitlesAllowed ? (SubtitlesFormatSchema.values.map((format) => (_jsx(Select.Option, { value: format, children: format === 'srt_FB' ? txt('exportSrtFB') : format }, format)))) : (SubtitlesFormatSchema.values
                        .filter((format) => session.login.hasClaim(`subtitles:${format.split('_')[0]}`))
                        .map((format) => (_jsx(Select.Option, { value: format, children: format === 'srt_FB' ? txt('exportSrtFB') : format }, format)))) }) }), _jsx(Form.Item, { name: isCaptionMode ? 'formatting' : 'upperCaseAllText', label: txt(isCaptionMode ? 'includeColorAndPosition' : 'setAllCaps'), valuePropName: "checked", children: _jsx(Switch, { disabled: isCaptionMode && NO_FORMATTING_SUPPORT_FORMATS.includes(subtitlesFormat), size: "small" }) }), (isCaptionMode && additionalInfo !== null) && (_jsx(Alert, { className: styles.formatAlert, type: "info", showIcon: true, message: additionalInfo })), !isCaptionMode && (_jsx(AlertWithButton, { type: "info", onClick: () => onSwitchToCaptionMode('downloadSubtitles'), message: txt('moreSettingsInfo'), buttonText: txt('convertToSubtitles') })), _jsxs("div", { className: styles.documentationLink, children: [txt('moreInfoOnSubs'), _jsx("a", { href: txt('captionTutorialLink'), target: "_blank", rel: "noreferrer", children: ` ${txt('readDocumentation')}.` })] })] }));
};
export default DownloadSubtitles;
