import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { txt } from 'libs/i18n';
import { subtitlesDefaults } from 'api/settings/user-settings';
import { Alert } from 'antd';
import { useEffect, useState } from 'react';
import RangeInput from '../RangeInput';
const SPEED_WARNING = {
    critical: { min: 16, max: 30 },
    normal: { min: 12, max: 30 },
};
const WarningsTab = ({ form, onValuesChange }) => {
    const [dependencyError, setDependencyError] = useState(null);
    const validateDependency = async (dependency) => {
        try {
            await form.validateFields([dependency]);
            setDependencyError(null);
        }
        catch (error) {
            const dependencyErrorMessage = form.getFieldError(dependency);
            if (dependencyErrorMessage.length > 0) {
                setDependencyError(dependencyErrorMessage[0]);
            }
        }
    };
    useEffect(() => {
        void validateDependency('automaticSpeed');
    }, [form.getFieldValue('automaticSpeed')]);
    const handleValuesChange = async () => {
        await validateDependency('automaticSpeed');
        onValuesChange();
    };
    return (_jsxs(_Fragment, { children: [_jsx(RangeInput, { label: txt('speedCriticalWarn'), name: "speedCriticalWarning", description: txt('speedCriticalWarnDescription'), min: SPEED_WARNING.critical.min, max: SPEED_WARNING.critical.max, step: 0.1, unit: txt('charactersPerSecond'), switchable: true, onValuesChange: handleValuesChange, restoreValue: subtitlesDefaults.speedCriticalWarning, disabledValue: null, dependencies: ['automaticSpeed'], form: form }), _jsx(RangeInput, { label: txt('speedWarn'), name: "speedWarning", description: txt('speedWarnDescription'), min: SPEED_WARNING.normal.min, max: SPEED_WARNING.normal.max, step: 0.1, unit: txt('charactersPerSecond'), switchable: true, dependencies: ['automaticSpeed'], onValuesChange: handleValuesChange, form: form, restoreValue: subtitlesDefaults.speedWarning, disabledValue: null, validator: ({ getFieldValue }) => ({
                    validator(_, value) {
                        const speedCriticalWarning = getFieldValue('speedCriticalWarning');
                        if (value === null) {
                            return Promise.resolve();
                        }
                        if (speedCriticalWarning !== null && value >= speedCriticalWarning) {
                            return Promise.reject(txt('speedWarnHigh'));
                        }
                        return Promise.resolve();
                    },
                }) }), dependencyError !== null && (_jsx(Alert, { showIcon: true, type: "error", message: `${dependencyError} ${txt('editWarningOrAdvanced')}` }))] }));
};
export default WarningsTab;
