import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { subtitlesDefaults } from 'api/settings/user-settings';
import { txt } from 'libs/i18n';
import { Form, Input, Radio, Select, Switch, } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import RangeInput from '../RangeInput';
const AUTOMATIC_SPEED = { min: 8, max: 30 };
const MINIMAL_TIME = { min: 0.1, max: 3 };
const AUTOFILL_PAUSE_BETWEEN_CAPTIONS_MS = { min: 0, max: 500 };
const AdvancedSettingsTab = ({ form, onValuesChange, isCaptionMode }) => {
    return (_jsxs(_Fragment, { children: [_jsx(RangeInput, { label: txt('automaticSpeed'), name: "automaticSpeed", description: txt('automaticSpeedDescription'), min: AUTOMATIC_SPEED.min, max: AUTOMATIC_SPEED.max, step: 0.1, unit: txt('charactersPerSecond'), switchable: true, onValuesChange: onValuesChange, form: form, restoreValue: subtitlesDefaults.automaticSpeed, disabledValue: null, dependencies: ['speedWarning', 'speedCriticalWarning'], validator: ({ getFieldValue }) => ({
                    validator(_, value) {
                        const speedWarning = getFieldValue('speedWarning');
                        const speedCriticalWarning = getFieldValue('speedCriticalWarning');
                        const hasSomeSpeedWarning = speedWarning !== null || speedCriticalWarning !== null;
                        if (value === null) {
                            if (hasSomeSpeedWarning) {
                                // Note: If some warning is set, automatic speed must be set.
                                return Promise.reject(txt('automaticSpeedRequired'));
                            }
                            return Promise.resolve();
                        }
                        if ((speedWarning !== null && value > speedWarning)
                            || (speedCriticalWarning !== null && value > speedCriticalWarning)) {
                            return Promise.reject(txt('automaticSpeedHigh'));
                        }
                        return Promise.resolve();
                    },
                }) }), _jsx(RangeInput, { label: txt('autofillPauseBetweenCaptions'), name: "autofillPauseBetweenCaptionsMs", description: txt('autofillPauseBetweenCaptionsInfo'), min: AUTOFILL_PAUSE_BETWEEN_CAPTIONS_MS.min, max: AUTOFILL_PAUSE_BETWEEN_CAPTIONS_MS.max, step: 10, unit: "ms", switchable: true, onValuesChange: onValuesChange, form: form, restoreValue: 500, disabledValue: 0 }), _jsx(RangeInput, { label: txt('minDuration'), name: "minDuration", description: txt('minDurationDescription'), min: MINIMAL_TIME.min, max: MINIMAL_TIME.max, step: 0.1, unit: txt('seconds'), required: true, form: form, restoreValue: subtitlesDefaults.minDuration }), _jsx(Form.Item, { label: txt('pauseBetweenCaptions'), name: "pauseBetweenCaptions", children: _jsx(Select, { options: [
                        {
                            value: 0,
                            label: '0 ms',
                        },
                        {
                            value: 0.08,
                            label: '80 ms',
                        },
                    ] }) }), _jsx(Form.Item, { hidden: true, name: "maxDuration", children: _jsx(Input, { value: subtitlesDefaults.maxDuration }) }), _jsx(Form.Item, { label: txt('spaceAtLineEnd'), name: "spaceAtLineEnd", valuePropName: "checked", children: _jsx(Switch, { size: "small" }) }), _jsx(Form.Item, { label: txt('speakerSignPlacement'), children: _jsx(Form.Item, { name: "speakerSignPlacement", noStyle: true, children: _jsx(Select, { options: [
                            {
                                value: 'utteranceStartOnly',
                                label: txt('utteranceStartOnly'),
                            },
                            {
                                value: 'multiSpeakerCaptionsOnly',
                                label: txt('multiSpeakerCaptionsOnly'),
                            },
                            {
                                value: 'none',
                                label: txt('noPlacement'),
                            },
                        ] }) }) }), _jsx(Form.Item, { label: txt('useSpeakerName'), name: "useSpeakerName", valuePropName: "checked", dependencies: ['speakerSignPlacement'], rules: [
                    ({ getFieldValue }) => ({
                        validator(_, value) {
                            if (getFieldValue('speakerSignPlacement') === 'none' && value) {
                                return Promise.reject(txt('nameRequiresSign'));
                            }
                            return Promise.resolve();
                        },
                    }),
                ], children: _jsx(Switch, { size: "small" }) }), _jsx(Form.Item, { name: "subtitlerMaxLineCount", label: txt('subtitlerMaxLineCount'), tooltip: isCaptionMode ? {
                    title: txt('changeDisabled'),
                    icon: _jsx(InfoCircleOutlined, {}),
                } : null, children: _jsxs(Radio.Group, { disabled: isCaptionMode, children: [_jsx(Radio, { value: 1, children: `1 ${txt('line')}` }), _jsx(Radio, { value: 2, children: `2 ${txt('twoLines')}` })] }) }), _jsx(Form.Item, { label: txt('enablePresegmentation'), tooltip: {
                    title: isCaptionMode ? txt('changeDisabled') : txt('enablePresegmentationTooltip'),
                    icon: _jsx(InfoCircleOutlined, {}),
                }, name: "enablePresegmentation", valuePropName: "checked", children: _jsx(Switch, { size: "small", disabled: isCaptionMode }) })] }));
};
export default AdvancedSettingsTab;
