import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { App, Button, Flex, Form, Input, Popover, } from 'antd';
import { txt } from 'libs/i18n';
import { useState } from 'react';
import { useSession } from 'components/Authenticated';
import styles from './style.module.less';
const NewTemplateButton = ({ children, newTemplateSettings, onTempleCreated, }) => {
    const { session, updateTeamSettings } = useSession();
    const [isOpen, setIsOpen] = useState(false);
    const { message } = App.useApp();
    const createTemplate = (values) => {
        const trimmedName = values.name.trim();
        if (session.teamSettings.subtitlesTemplates.some((template) => template.name === trimmedName)) {
            void message.error(txt('templateAlreadyExists'));
            return;
        }
        updateTeamSettings({
            subtitlesTemplates: [
                ...session.teamSettings.subtitlesTemplates,
                { name: trimmedName, settings: newTemplateSettings, isEditable: true },
            ],
        });
        void message.success(txt('templateCreated'));
        setIsOpen(false);
        onTempleCreated(trimmedName);
    };
    const content = (_jsxs(Form, { className: styles.form, onFinish: createTemplate, children: [_jsx(Form.Item, { label: txt('name'), name: "name", rules: [{ required: true, whitespace: true, message: txt('nameTemplate') }], children: _jsx(Input, {}) }), _jsxs(Flex, { className: styles.buttons, justify: "end", gap: "small", children: [_jsx(Button, { onClick: () => setIsOpen(false), size: "small", children: txt('cancel') }), _jsx(Button, { size: "small", type: "primary", htmlType: "submit", children: txt('create') })] })] }));
    return (_jsx(Popover, { trigger: "click", title: txt('addTemplate'), content: content, open: isOpen, onOpenChange: setIsOpen, children: children }));
};
export default NewTemplateButton;
