import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Form, Select, Switch } from 'antd';
import { subtitlesDefaults, TransparencySchema } from 'api/settings/user-settings';
import SpeakerColorPicker from 'components/SpeakerColorPicker';
import { txt } from 'libs/i18n';
import { InfoCircleOutlined } from '@ant-design/icons';
import { captionFontNames } from '../caption-fonts';
import RangeInput from '../RangeInput';
import CaptionPosition from '../CaptionPosition';
import styles from './style.module.less';
const { Option } = Select;
export const SUBTITLE_LENGTH = { min: 15, max: 60 };
const FormatTab = ({ form, isCaptionMode, onValuesChange, initialValues, }) => {
    const fontSizeOptions = () => {
        const options = [];
        const minFontSize = 8;
        const maxFontSize = 40;
        for (let i = minFontSize; i <= maxFontSize; i += 2) {
            options.push(_jsx(Option, { value: i, children: i }, i));
        }
        return options;
    };
    return (_jsxs(_Fragment, { children: [_jsx(RangeInput, { label: txt('lineLength'), name: "maxLineLength", description: txt('maxLineLengthDescription'), min: SUBTITLE_LENGTH.min, max: SUBTITLE_LENGTH.max, step: 1, unit: txt('charactersPerLine'), form: form, disabled: isCaptionMode, restoreValue: subtitlesDefaults.maxLineLength }), _jsx(Form.Item, { label: txt('defaultSubsColor'), name: "defaultColor", children: _jsx(SpeakerColorPicker, { onChange: (color) => form.setFieldValue('defaultColor', color) }) }), _jsxs(Form.Item, { label: txt('fontStyleAndSize'), className: styles.font, children: [_jsx(Form.Item, { name: "defaultFontName", noStyle: true, children: _jsx(Select, { children: captionFontNames.map((defaultFontName) => (_jsx(Option, { value: defaultFontName, children: _jsx("span", { style: { fontFamily: defaultFontName }, children: defaultFontName }) }, defaultFontName))) }) }), _jsx(Form.Item, { name: "defaultFontSize", noStyle: true, children: _jsx(Select, { className: "font-size", children: fontSizeOptions() }) })] }), _jsx(Form.Item, { name: "defaultBackgroundTransparency", label: txt('backgroundTransparency'), children: _jsx(Select, { children: TransparencySchema.entries().map(([key, literal]) => (_jsx(Option, { value: literal.value, children: txt(key) }, key))) }) }), _jsx(Form.Item, { name: "defaultCaptionPosition", label: txt('subtitlePosition'), tooltip: {
                    title: txt('defaultPosition'),
                    icon: _jsx(InfoCircleOutlined, {}),
                }, children: _jsx(CaptionPosition, { onValuesChange: onValuesChange, form: form, initialValue: initialValues.defaultCaptionPosition }) }), _jsx(Form.Item, { name: "upperCaseAllText", label: txt('setAllCaps'), valuePropName: "checked", children: _jsx(Switch, { size: "small" }) })] }));
};
export default FormatTab;
