import { jsx as _jsx } from "react/jsx-runtime";
import { App } from 'antd';
import { fetchProjectTrsx } from 'api/project-api';
import { txt } from 'libs/i18n';
import { useSession } from 'components/Authenticated';
import AlertWithButton from 'components/AlertWithButton';
import { useSubModeTutorial } from '../../../subModeTutorial';
const ResetTab = ({ onCancel, editorController, project }) => {
    const { session } = useSession();
    const { message } = App.useApp();
    const { retrieveTutorialState } = useSubModeTutorial();
    const turnOffsubtitleRevision = async () => {
        try {
            const trsx = await fetchProjectTrsx(session.connection, project);
            editorController.importTrsx(trsx, true, project, true);
            void message.success(txt('captionsRemoved'));
        }
        catch (error) {
            void message.error(txt('removeCaptionsFailed'));
            global.logger.error('Failed to reset captions', {}, error);
        }
        onCancel();
    };
    return (_jsx(AlertWithButton, { message: txt('subtitleResetWarning'), type: "warning", buttonType: "danger", disabled: retrieveTutorialState() === 'running', onClick: turnOffsubtitleRevision, buttonText: txt('switchToTranscription') }));
};
export default ResetTab;
