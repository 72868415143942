import Quill from 'quill';
import { deleteSpeaker } from 'api/speaker-api';
import { Speaker } from 'api/speaker';
import { Modal } from 'antd';
import { txt } from 'libs/i18n';
import Delta from 'quill-delta';
import { fastCompose } from 'libs/quill-utils';
import SpeakerIdentification from './speaker-identification';
import { createIntervalGraph, colorIntervals } from './color-intervals';
import { SPACE_REGEX_G } from './text-utils';
export const SPEAKER_EVENTS = Object.freeze({
    CHANGED: 'changed',
});
export default class Speakers {
    constructor(editorController, session) {
        this.wasLastSpeakerNew = false;
        this.replaceAllSpeakers = (prevSpeakerId, newSpeaker) => {
            const speakerNodes = this.quill.root.getElementsByTagName('h4');
            this.editorController.getHistory().cutoff();
            let replaceAllSpeakersDelta = new Delta();
            const speakersMetadata = [];
            for (let i = speakerNodes.length - 1; i >= 0; i -= 1) {
                const speakerNode = speakerNodes[i];
                if (Speaker.getId(speakerNode) === prevSpeakerId) {
                    const blot = Quill.find(speakerNode);
                    const beginIndex = this.quill.getIndex(blot);
                    const endIndex = this.quill.getIndex(blot.next);
                    const currentMetadata = this.editorController.textMetadata.getMetadataAtIndex(endIndex - 1, 'speakerSign');
                    const label = newSpeaker.composeLabel();
                    const format = {
                        speaker: newSpeaker.getQuillFormatValue(),
                        // NOTE: forces span inside H4, necessary for #479
                        color: '#666',
                    };
                    const currentSpeakerDelta = new Delta()
                        .retain(beginIndex)
                        .delete(endIndex - beginIndex)
                        .insert(`${label}\n`, format);
                    replaceAllSpeakersDelta = fastCompose(replaceAllSpeakersDelta, currentSpeakerDelta);
                    if (currentMetadata !== null) {
                        speakersMetadata.push([endIndex - 1, currentMetadata]);
                    }
                }
            }
            this.editorController.updateContents(replaceAllSpeakersDelta, 'user');
            // NOTE: When replacing speakers, their metadata is deleted.
            // To preserve forceDisplay information, we add it again.
            speakersMetadata.forEach((speakerMetadata) => {
                this.editorController.textMetadata.addMetadata('speakerSign', speakerMetadata[0], speakerMetadata[1]);
            });
            void this.editorController.captions.resolveUpdateRequests();
        };
        this.addSpeakerOnIndex = (speaker, offset_, source) => {
            var _a;
            let offset = offset_;
            const format = {
                speaker: speaker.getQuillFormatValue(),
                color: '#666', // forces span inside H4, necessary for #479
                timeAnchor: false,
            };
            const prevChar = this.editorController.getText(offset - 1, 1);
            if (prevChar !== '\n') {
                // There must be a newline before the speaker.
                this.editorController.insertText(offset, '\n', source);
                offset += 1;
            }
            if (this.editorController.getLineFormat(offset - 1).speaker !== undefined) {
                // replace another speaker
                const previousSpeaker = this.editorController.getBlock(offset - 1);
                const previousSpeakerLength = previousSpeaker.length();
                this.removeSpeaker(previousSpeaker, source);
                offset -= previousSpeakerLength;
            }
            if (offset === this.editorController.getLength()) {
                // NOTE: This prevents inserting speaker after the document end.
                this.editorController.insertText(offset - 1, '\n');
            }
            const nextChar = this.editorController.getText(offset, 1);
            // NOTE: do not add additional line if inserting in a blank paragraph
            if (nextChar === '\n' && offset !== this.editorController.getLength() - 1) {
                if (((_a = this.editorController.getLastSelection()) === null || _a === void 0 ? void 0 : _a.index) === offset) {
                    // NOTE: The newline that was deleted was the previous selection
                    // selection will be pushed to the start of the next line.
                    this.editorController.focus(offset + 1);
                }
                this.editorController.deleteText(offset, 1, source);
            }
            const label = speaker.composeLabel();
            this.editorController.insertTextWithFormat(offset, `${label}\n`, format, source);
            this.editorController.captions.requestUpdate(offset, offset + label.length + 1, true);
            return offset;
        };
        this.removeSpeaker = (speakerBlot, source) => {
            const previousStart = speakerBlot.offset();
            const previousLength = speakerBlot.length();
            this.editorController.deleteText(previousStart, previousLength, source);
        };
        this.removeSpeakerOnIndex = (index, source) => {
            const speakerBlot = this.editorController.getBlock(index);
            this.removeSpeaker(speakerBlot, source);
        };
        this.addSpeakerBeforeParagraph = (speaker) => {
            var _a, _b;
            const block = this.editorController.getBlock((_b = (_a = this.editorController.getLastSelection()) === null || _a === void 0 ? void 0 : _a.index) !== null && _b !== void 0 ? _b : 0);
            const targetOffset = block.offset();
            this.addSpeakerOnIndex(speaker, targetOffset, 'user');
        };
        this.addSpeakerOnCaret = (speaker) => {
            const selection = this.editorController.getLastSelection();
            if (selection === null) {
                global.logger.error('no last selection');
                return;
            }
            const selectionIndex = selection.index;
            let index = this.editorController.getWordStart(
            // NOTE: There is +1 to handle the case when selection is on word end.
            selectionIndex + 1);
            if (this.editorController.getText(selectionIndex - 1, 2) === '\n\n') {
                // NOTE: If the cursor is in an empty line, ignore word start and insert
                // into this empty line.
                index = selectionIndex;
            }
            const prevChar = this.editorController.getText(index - 1, 1);
            if (prevChar !== '\n' && prevChar.match(SPACE_REGEX_G)) {
                this.editorController.deleteText(index - 1, 1, 'user');
                this.editorController.insertText(index - 1, '\n', 'user');
            }
            else if (prevChar !== '\n') {
                this.editorController.insertText(index, '\n', 'user');
                index += 1;
            }
            const newSpeakerOffset = this.addSpeakerOnIndex(speaker, index, 'user');
            this.editorController.focus(newSpeakerOffset + speaker.composeLabel().length);
        };
        this.addDummySpeakerOnCaret = () => {
            var _a;
            // Ideally, this function should insert unknown speaker without a number.
            // However, that causes problems in ingestigator, so for now we create
            // new unknown speaker with number. We can change it back after advanced
            // coloring of speakers is implemented in editor.
            const newUnknownSpeaker = this.speakerIdentification.createSpeaker(undefined, (_a = this.editorController.captions.parameters) === null || _a === void 0 ? void 0 : _a.defaultColor);
            this.addDocumentSpeaker(newUnknownSpeaker);
            this.addSpeakerOnCaret(newUnknownSpeaker);
        };
        this.createSpeakerOnEnd = (diarizationCode) => {
            this.editorController.execTextChange({ runAligner: false, requestSave: false }, () => {
                const lastBlockFormat = this.editorController.getLineFormat(this.editorController.getLength() - 2);
                if (lastBlockFormat.speaker !== undefined && this.wasLastSpeakerNew) {
                    // replacing another speaker that had empty utterance and which
                    // never had any real utterance. This speaker should be completely forgotten.
                    const replacedSpeaker = this.findLastSpeaker(this.editorController.getLength());
                    this.removeDocumentSpeaker(replacedSpeaker.id); // forget speaker completely
                    this.speakerIdentification.speakerCounter -= 1;
                }
                const previousCounter = this.speakerIdentification.speakerCounter;
                const diarizationSpeaker = this.speakerIdentification.getOrCreateSpeaker(diarizationCode);
                this.editorController.speakers.addDocumentSpeaker(diarizationSpeaker);
                this.wasLastSpeakerNew = previousCounter !== this.speakerIdentification.speakerCounter;
                this.addSpeakerOnIndex(diarizationSpeaker, this.editorController.getLength() - 1, 'api');
                const speakerBlock = this.editorController.getBlock(this.editorController.getLength() - 2);
                this.speakerIdentification.addTemporarySpeaker(speakerBlock);
                const lastButOneSpeaker = this.findLastSpeaker(this.editorController.getLength() - 2);
                if (diarizationCode === (lastButOneSpeaker === null || lastButOneSpeaker === void 0 ? void 0 : lastButOneSpeaker.diarizationCode)) {
                    // Repeating speaker should not have a new speaker label.
                    // However it indicates a new paragraph.
                    this.removeSpeaker(speakerBlock, 'api');
                }
            });
        };
        this.findLastSpeaker = (index) => {
            const text = this.editorController.getText(0, index);
            let lastNewLineIndex = text.lastIndexOf('\n');
            while (lastNewLineIndex > 0) {
                const speakerFormat = this.editorController.getLineFormat(lastNewLineIndex).speaker;
                if (speakerFormat !== undefined) {
                    const speakerId = speakerFormat.split(' ')[0];
                    return this.getSpeakerById(speakerId);
                }
                lastNewLineIndex = text.lastIndexOf('\n', lastNewLineIndex - 1);
            }
            return null;
        };
        this.deleteSpeakerFromDatabase = (speaker, removeFromDocument) => {
            const label = speaker.composeLabel();
            const { confirm } = Modal;
            const { session, removeDocumentSpeaker } = this;
            confirm({
                title: txt('confirmDelete'),
                content: `${label} ${txt('confirmDeleteContent')}`,
                okText: txt('yesDelete'),
                cancelText: txt('cancel'),
                okType: 'danger',
                onOk() {
                    if (speaker.dbid !== null) {
                        void deleteSpeaker(session.connection, speaker.dbid);
                    }
                    if (removeFromDocument) {
                        removeDocumentSpeaker(speaker.id);
                    }
                },
            });
        };
        this.getSpeakerById = (id) => {
            for (let i = 0; i < this.documentSpeakers.length; i += 1) {
                const speaker = this.documentSpeakers[i];
                if (speaker.id === id) {
                    return speaker;
                }
            }
            return null;
        };
        this.getSpeakerByDbid = (dbid) => {
            for (let i = 0; i < this.documentSpeakers.length; i += 1) {
                const speaker = this.documentSpeakers[i];
                if (speaker.dbid === dbid) {
                    return speaker;
                }
            }
            return null;
        };
        this.getSpeakerByDiarizationCode = (diarizationCode) => {
            // NOTE: We go from end because renamed or colored speakers are added to the document end.
            for (let i = this.documentSpeakers.length - 1; i >= 0; i -= 1) {
                const speaker = this.documentSpeakers[i];
                if (speaker.diarizationCode === diarizationCode) {
                    return speaker;
                }
            }
            return null;
        };
        this.getSpeakerIndexRange = (speakerNode) => {
            const blot = Quill.find(speakerNode);
            if (blot === null) {
                return null;
            }
            const begin = this.quill.getIndex(blot);
            const end = this.quill.getIndex(blot.next);
            return { begin, end };
        };
        this.getSpeakerTimeRange = (speakerNode) => {
            const speakerIndexRange = this.getSpeakerIndexRange(speakerNode);
            if (speakerIndexRange === null) {
                return null;
            }
            const nextSpeakerIndex = this.getNextSpeakerIndex(speakerIndexRange.end);
            const begin = this.editorController.textMetadata.getBeginAtIndex(speakerIndexRange.begin);
            const end = this.editorController.textMetadata.getEndAtIndex(nextSpeakerIndex - 1);
            return { begin, end };
        };
        this.getSpeakerByIndex = (index) => {
            const lineFormat = this.editorController.getLineFormat(index);
            if (lineFormat.speaker !== undefined) {
                const speakerId = lineFormat.speaker.split(' ')[0];
                const speaker = this.getSpeakerById(speakerId);
                if (speaker === null) {
                    global.logger.error('speaker not found', { speakerId });
                }
                return speaker;
            }
            return null;
        };
        this.setDocumentSpeakers = (speakers) => {
            this.documentSpeakers = speakers;
        };
        this.addDocumentSpeaker = (speaker) => {
            this.removeDocumentSpeaker(speaker.id);
            this.documentSpeakers.push(speaker);
        };
        this.getDisplayedSpeakerNames = () => {
            const speakerNodes = this.quill.root.getElementsByTagName('h4');
            const speakerNames = [];
            for (let i = 0; i < speakerNodes.length; i += 1) {
                speakerNames.push(speakerNodes[i].innerText);
            }
            return speakerNames;
        };
        this.getSpeakerFrequencyInDocument = (speaker) => {
            return this.getDisplayedSpeakerNames().reduce((count, speakerName) => {
                let speakerCount = count;
                if (speakerName === speaker.composeLabel()) {
                    speakerCount += 1;
                }
                return speakerCount;
            }, 0);
        };
        this.getSpeakerByElement = (domNode) => {
            return this.getSpeakerById(Speaker.getId(domNode));
        };
        this.getSpeakerColorConflict = (speakerId) => {
            const speakerNodes = this.quill.root.getElementsByTagName('h4');
            for (let i = 0; i < speakerNodes.length - 1; i += 1) {
                const currentSpeaker = this.getSpeakerByElement(speakerNodes[i]);
                if ((currentSpeaker === null || currentSpeaker === void 0 ? void 0 : currentSpeaker.id) === speakerId) {
                    const nextSpeaker = this.getSpeakerByElement(i + 1 < speakerNodes.length ? speakerNodes[i + 1] : speakerNodes[speakerNodes.length - 1]);
                    if (currentSpeaker.captionColor === (nextSpeaker === null || nextSpeaker === void 0 ? void 0 : nextSpeaker.captionColor)) {
                        return nextSpeaker.composeLabel();
                    }
                }
            }
            return null;
        };
        // NOTE: With each change new documentSpeaker is created so there may be duplicates.
        this.getUniqueDocumentSpeakers = () => {
            const { documentSpeakers } = this.editorController.speakers;
            const uniqueSpeakers = [];
            for (let i = documentSpeakers.length - 1; i >= 0; i -= 1) {
                const speakerName = documentSpeakers[i].composeLabel();
                if (!this.getDisplayedSpeakerNames().includes(speakerName)) {
                    continue;
                }
                if (uniqueSpeakers.find((speaker) => speaker.composeLabel() === speakerName) !== undefined) {
                    continue;
                }
                uniqueSpeakers.push(documentSpeakers[i]);
            }
            return uniqueSpeakers;
        };
        this.assignSpeakersColors = (colors) => {
            const speakerIntervals = this.getSpeakersWithTimestamps(1);
            const speakerIntervalGraph = createIntervalGraph(speakerIntervals);
            return colorIntervals(speakerIntervalGraph, speakerIntervals, colors);
        };
        this.getSpeakerNodes = () => {
            return this.quill.root.getElementsByTagName('h4');
        };
        // NOTE: intervalStretch makes speakers overlap to eliminate gaps between them.
        this.getSpeakersWithTimestamps = (intervalStretch = 0) => {
            const timestampedSpeakers = [];
            const speakerNodes = this.quill.root.getElementsByTagName('h4');
            for (let i = 0; i < speakerNodes.length; i += 1) {
                const currentSpeakerNode = speakerNodes[i];
                const nextSpeakerNode = i + 1 < speakerNodes.length
                    ? speakerNodes[i + 1] : speakerNodes[speakerNodes.length - 1];
                const currentBlot = Quill.find(currentSpeakerNode);
                const nextBlot = Quill.find(nextSpeakerNode);
                const firstWordIndex = this.quill.getIndex(currentBlot.next) + 1;
                const lastWordIndex = i + 1 < speakerNodes.length
                    ? this.editorController.getLength() - 2 : this.quill.getIndex(nextBlot) - 1;
                const firstOccurrence = Math.round(this.editorController.textMetadata.getBeginAtIndex(firstWordIndex) - intervalStretch);
                const lastOccurrence = Math.round(this.editorController.textMetadata.getEndAtIndex(lastWordIndex)) + intervalStretch;
                const timeStampedIndex = timestampedSpeakers.findIndex((speaker) => speaker.id === Speaker.getId(currentSpeakerNode));
                if (timeStampedIndex >= 0) {
                    timestampedSpeakers[timeStampedIndex].lastEnd = lastOccurrence;
                }
                else {
                    timestampedSpeakers.push({
                        id: Speaker.getId(currentSpeakerNode),
                        firstBegin: firstOccurrence,
                        lastEnd: lastOccurrence,
                    });
                }
            }
            return timestampedSpeakers;
        };
        this.removeDocumentSpeaker = (id) => {
            let i = 0;
            while (i < this.documentSpeakers.length) {
                const speaker = this.documentSpeakers[i];
                if (speaker.id === id) {
                    this.documentSpeakers.splice(i, 1);
                }
                else {
                    i += 1;
                }
            }
        };
        this.getNextSpeakerIndex = (currentSpeakerIndex) => {
            const nextBlocks = this.editorController.getBlockStarts(currentSpeakerIndex + 1, this.editorController.getLength() - 1);
            for (let i = 0; i < nextBlocks.length; i += 1) {
                if (this.editorController.getLineFormat(nextBlocks[i]).speaker !== undefined) {
                    return nextBlocks[i];
                }
            }
            return this.editorController.getLength() - 1;
        };
        this.editorController = editorController;
        if (editorController.quill === undefined) {
            throw Error('cannot initialize speakers because quill is undefined');
        }
        this.quill = editorController.quill;
        this.documentSpeakers = [];
        this.session = session;
        this.speakerIdentification = new SpeakerIdentification(this.editorController);
    }
    handleTextChanged() {
        let shouldContinueLoop = true;
        while (shouldContinueLoop) {
            // NOTE: All speakers must have some utterance or they won't be saved to trsx.
            shouldContinueLoop = this.addLineAfterSpeakersWithNoUtterance();
        }
    }
    addLineAfterSpeakersWithNoUtterance() {
        const blockStarts = this.editorController.getBlockStarts(0, this.editorController.getLength() - 1);
        const selection = this.editorController.getSelection();
        if (selection === null) {
            return false;
        }
        for (let i = 1; i <= blockStarts.length - 1; i += 1) {
            const previousBlockStart = blockStarts[i - 1];
            const currentBlockStart = blockStarts[i];
            const previousFormat = this.editorController.getLineFormat(previousBlockStart);
            const currentFormat = this.editorController.getLineFormat(currentBlockStart);
            const selectionFormat = this.editorController.getLineFormat(selection.index);
            if (previousFormat.speaker !== undefined
                && this.editorController.isNonTranscriptFormat(currentFormat)
                && !this.editorController.isNonTranscriptFormat(selectionFormat)) {
                this.editorController.insertTextWithFormat(currentBlockStart, '\n', { speaker: null, header: null, section: false });
                if (selection.length > 0) {
                    // NOTE: This is a solution of bug https://www.notion.so/Vytvo-se-n-kolik-sumariza-n-ch-poli-kdy-v-cekr-t-kliknu-H2-bb861adefee5458fb893ea84448057b8?pvs=4
                    // The problem was that selection was expanded to the newly created line.
                    this.editorController.setSelection(selection.index + 1, selection.length - 1);
                }
                if (selection.length === 0) {
                    // NOTE: When creating heading under the speaker, selection should be on the line
                    // with the heading, not on the newly created newline.
                    this.editorController.setSelection(selection.index + 1, 0);
                }
                return true;
            }
        }
        return false;
    }
}
