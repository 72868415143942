import { blob, json } from '@newtontechnologies/beey-api-js-client/receivers';
import { transcodeApi, linkApi, aiServantApi, } from './endpoints';
const APP_URL_BASE = '/apps';
export const createAppLink = (appPath) => (`${APP_URL_BASE}${appPath}`);
export const fetchJobsOnProject = async (connection, projectId) => connection.authFetch()
    .url(transcodeApi.job.id(projectId).groupStatus.url())
    .receive(json())
    .fetch();
export const fetchJobStatus = async (connection, id) => connection.authFetch()
    .url(transcodeApi.job.id(id).status.url())
    .receive(json())
    .fetch();
export const createBurntCaptionsJob = async (connection, name, begin, end, projectId, workerName) => {
    const body = new FormData();
    body.append('subsFileName', `${name}.vtt`);
    body.append('start', begin);
    body.append('end', end);
    body.append('beeyProjectId', String(projectId));
    body.append('workerName', workerName);
    return connection.authFetch()
        .url(transcodeApi.burnSubs.url())
        .post(body)
        .receive(json())
        .fetch();
};
export const createCutMediaJob = async (connection, begin, end, projectId, workerName) => {
    const body = new FormData();
    body.append('beeyProjectId', String(projectId));
    body.append('start', begin);
    body.append('end', end);
    body.append('workerName', workerName);
    body.append('token', await connection.retrieveAuthString());
    return connection.authFetch()
        .url(transcodeApi.cut.url())
        .post(body)
        .receive(json())
        .fetch();
};
export const removeJob = async (connection, id) => connection.authFetch()
    .url(transcodeApi.job.id(id).remove.url())
    .post()
    .send();
export const startJob = async (connection, id) => connection.authFetch()
    .url(transcodeApi.job.id(id).start.url())
    .send();
export const uploadCaptionsForBurn = async (connection, captionsBlob, id, name) => {
    const body = new FormData();
    body.append('jobID', id);
    body.append('file', captionsBlob, `${name}.vtt`);
    return connection.authFetch()
        .url(transcodeApi.upload.url())
        .post(body)
        .send();
};
export const downloadModifiedVideo = async (connection, id) => {
    const body = new FormData();
    body.append('jobID', id.toString());
    body.append('-', '-O');
    body.append('-', '-J');
    return connection.authFetch()
        .url(transcodeApi.download.url())
        .post(body)
        .receive(blob())
        .fetch();
};
export const transcribeLink = async (connection, url, projectName, projectId, transcribeAttrs) => {
    const body = {
        url,
        name: projectName,
        transcriptionConfig: {
            language: transcribeAttrs.language.code,
            profile: transcribeAttrs.profile,
            withPPC: transcribeAttrs.usePPC,
            withVAD: transcribeAttrs.useVAD,
            withDiarization: transcribeAttrs.useDiarization,
            withSpeakerId: transcribeAttrs.useSpeakerId,
        },
        projectID: projectId,
    };
    return connection.authFetch()
        .url(linkApi.ytDl.transcribe.url())
        .postJson(body)
        .send();
};
export const fetchLinkDetail = async (connection, url) => connection.authFetch()
    .url(linkApi.ytDl.urlInfo.url())
    .postJson({ url })
    .receive(json())
    .fetch();
export const summarizeProject = async (connection, projectID, targetLanguage, begin, end) => connection.authFetch()
    .url(aiServantApi.summarize.project.url({
    projectId: projectID,
    targetLanguage: targetLanguage.code,
    begin,
    end,
}))
    .receive(json())
    .fetch();
export const translateProject = async (connection, sourceLanguage, targetLanguage, projectId) => {
    var _a;
    return connection.authFetch()
        .url(aiServantApi.translate.project.url({
        projectId,
        sourceLanguage: (_a = sourceLanguage === null || sourceLanguage === void 0 ? void 0 : sourceLanguage.code) !== null && _a !== void 0 ? _a : null,
        targetLanguage: targetLanguage.code,
    }))
        .receive(json())
        .fetch();
};
