import { createUserFromApi, } from 'api/model/user';
import { apiV2 } from '@newtontechnologies/beey-api-js-client/endpoints';
import { defaultUserSettings } from 'api/settings/user-settings';
import { BeeyLocale } from 'libs/locales/locale';
import { blob, json } from '@newtontechnologies/beey-api-js-client/receivers';
export const fetchTeam = async (connection, userId) => connection.authFetch()
    .url(apiV2.admin.teams.id(userId).url())
    .receive(json())
    .fetch();
export const fetchTeamTranscriptions = async (connection, teamId, dates, orderBy, ascending, skip, count) => {
    const params = {
        skip,
        count,
        from: dates[0],
        to: dates[1],
        orderBy,
        ascending,
    };
    return connection.authFetch()
        .url(apiV2.admin.teams.id(teamId).transcribedPerPeriod.url(params))
        .receive(json())
        .fetch();
};
export const addTeamCredit = async (connection, teamId, addedCredit) => connection.authFetch()
    .url(apiV2.admin.teams.id(teamId).credit.add.url())
    .putJson({ credit: addedCredit })
    .send();
export const removeTeamCredit = async (connection, teamId, removedCredit) => connection.authFetch()
    .url(apiV2.admin.teams.id(teamId).credit.subtract.url())
    .putJson({ Credit: removedCredit })
    .send();
export const exportTeamMembers = async (connection, teamId, dates, orderBy, ascending) => connection.authFetch()
    .url(apiV2.admin.teams.id(teamId).exportMembers.url({
    from: dates[0],
    to: dates[1],
    orderBy,
    ascending,
}))
    .receive(blob())
    .fetch();
export const deleteTeam = async (connection, teamId) => connection.authFetch()
    .url(apiV2.admin.teams.id(teamId).url())
    .delete()
    .send();
export const setManagementGroup = async (connection, teamId, groupId) => connection.authFetch()
    .url(apiV2.admin.teams.id(teamId).setManagementGroup.url())
    .postJson({
    ManagementGroupId: groupId,
})
    .receive(json())
    .fetch();
export const fetchSpeakerIdModels = async (connection, teamId) => {
    const models = await connection.authFetch()
        .url(apiV2.admin.teams.id(teamId).voiceprintModels.url())
        .receive(json())
        .fetch();
    return models.map((model) => (Object.assign(Object.assign({}, model), { language: BeeyLocale.const(model.language) })));
};
export const deleteSpeakerIdModel = async (connection, teamId, locale) => connection.authFetch()
    .url(apiV2.admin.teams.id(teamId).voiceprintModels.url({ language: locale.code }))
    .delete()
    .send();
export const uploadSpeakerIdModel = async (connection, teamId, model, locale) => {
    const body = new FormData();
    body.append('Files', model);
    return connection.authFetch()
        .url(apiV2.admin.teams.id(teamId).voiceprintModels.url({ language: locale.code }))
        .put(body)
        .send();
};
export const abandonProjectsIn = async (connection, teamId, abandonProjects) => {
    const body = {
        Age: abandonProjects.inDays === null ? null : `${String(abandonProjects.inDays)}.00:00:00`,
    };
    return connection.authFetch()
        .url(apiV2.admin.teams.id(teamId).abandonProjectsIn.url())
        .postJson(body)
        .send();
};
export const addTeamMember = async (connection, email, role, password, tutorial, sendEmail, uiLocale, teamId) => {
    const settings = Object.assign(Object.assign({}, defaultUserSettings), { startBeeyTutorial: tutorial });
    const body = {
        Email: email.trim(),
        Password: password,
        Role: role,
        Language: uiLocale.code,
        Settings: settings,
    };
    return connection.authFetch()
        .url(apiV2.admin.teams.id(teamId).members.url({ sendEmail }))
        .postJson(body)
        .receive(json().map(createUserFromApi))
        .fetch();
};
export const fetchStorageStatistics = async (connection, userId, userDataOnly) => connection.authFetch()
    .url(apiV2.admin.teams.id(userId).storageStatistics.url({ userDataOnly }))
    .receive(json())
    .fetch();
export const fetchExportTemplates = async (connection, teamId) => connection.authFetch()
    .url(apiV2.admin.teams.id(teamId).exportTemplates.url())
    .receive(json())
    .fetch();
export const uploadExportTemplate = async (connection, teamId, name, template) => {
    const body = new FormData();
    body.append('Files', template);
    return connection.authFetch()
        .url(apiV2.admin.teams.id(teamId).exportTemplates.url({ Name: name }))
        .put(body)
        .send();
};
export const deleteExportTemplate = async (connection, teamId, template) => connection.authFetch()
    .url(apiV2.admin.teams.id(teamId).exportTemplates.url({ templateId: template }))
    .delete()
    .send();
export const fetchTeamPlan = async (connection, teamId) => connection.authFetch()
    .url(apiV2.admin.teams.id(teamId).plan.url())
    .receive(json())
    .fetch();
export const fetchTeamSubscriptions = async (connection, teamId) => connection.authFetch()
    .url(apiV2.admin.subscriptions.id(teamId).url())
    .receive(json())
    .fetch();
export const transferOwnership = async (connection, teamId, newOwnerId) => {
    const body = { NewOwnerId: newOwnerId };
    return (connection.authFetch()
        .url(apiV2.admin.teams.id(teamId).transferOwnership.url())
        .putJson(body)
        .send());
};
export const transferMemberToTeam = async (connection, teamId, memberId, newTeamId) => {
    const body = {
        MemberId: memberId,
        TargetTeamId: newTeamId,
    };
    return (connection.authFetch()
        .url(apiV2.admin.teams.id(teamId).migrateMember.url())
        .putJson(body)
        .send());
};
