import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { txt } from 'libs/i18n';
import corgi from 'resources/img/corgi.png';
import { useRef } from 'react';
import { Form } from 'antd';
import { getRelativeSubsFontSize } from 'libs/subs-font-size';
import { subtitlesDefaults } from 'api/settings/user-settings';
import clsx from 'clsx';
import styles from './style.module.less';
const CaptionPreview = ({ subtitleSettings }) => {
    var _a, _b, _c;
    const previewRef = useRef(null);
    const calculateFontSize = () => {
        var _a;
        const { defaultFontSize } = subtitleSettings;
        const height = (_a = previewRef.current) === null || _a === void 0 ? void 0 : _a.getBoundingClientRect().height;
        if (defaultFontSize === undefined || height === undefined) {
            return null;
        }
        return getRelativeSubsFontSize(defaultFontSize, height);
    };
    const captionPosition = (_a = subtitleSettings.defaultCaptionPosition) !== null && _a !== void 0 ? _a : {
        align: 'center',
        line: 100,
    };
    const computeVerticalPosition = () => {
        if (captionPosition.line === 100) {
            return { bottom: 0 };
        }
        if (captionPosition.line === 50) {
            return {
                bottom: '50%',
                transform: 'translateY(50%)',
            };
        }
        return { top: 0 };
    };
    const computeHorizontalPosition = () => {
        if (captionPosition.align === 'left') {
            return 'flex-start';
        }
        if (captionPosition.align === 'right') {
            return 'flex-end';
        }
        return 'center';
    };
    const getBackgroundColor = () => {
        const { defaultBackgroundTransparency } = subtitleSettings;
        if (defaultBackgroundTransparency === 0) {
            return 'black';
        }
        if (defaultBackgroundTransparency === 0.5) {
            return 'semi';
        }
        return 'transparent';
    };
    const previewStyle = Object.assign({ color: subtitleSettings.defaultColor, fontFamily: subtitleSettings.defaultFontName, fontSize: (_b = calculateFontSize()) !== null && _b !== void 0 ? _b : subtitlesDefaults.fontSize, textTransform: subtitleSettings.upperCaseAllText === true ? 'uppercase' : 'none', alignItems: computeHorizontalPosition() }, computeVerticalPosition());
    const previewFormat = {
        backgroundColor: getBackgroundColor(),
        lineCount: subtitleSettings.subtitlerMaxLineCount,
        showSpeakerSign: subtitleSettings.speakerSignPlacement !== 'none',
        showSpeakerName: subtitleSettings.useSpeakerName,
        highlightingMode: subtitleSettings.highlightingMode,
        unHighlightedColor: (_c = subtitleSettings.unHighlightedColor) !== null && _c !== void 0 ? _c : subtitlesDefaults.unHighlightedColor,
    };
    const formatCaption = (text, name) => {
        if (!previewFormat.showSpeakerSign) {
            return text;
        }
        if (previewFormat.showSpeakerName === true) {
            return `-${name}: ${text}`;
        }
        return `-${text}`;
    };
    const createAnimatedText = (text) => {
        const words = text.split(' ');
        const textBeforeHighlight = words.slice(0, 3).join(' ');
        const highlightedWord = words[3];
        const textBeforeWithHighlight = words.slice(0, 4).join(' ');
        const textAfterHighlight = words.slice(4).join(' ');
        const { highlightingMode, unHighlightedColor } = previewFormat;
        switch (highlightingMode) {
            case 'CurrentWord':
                return (_jsxs(_Fragment, { children: [_jsx("span", { style: { color: unHighlightedColor }, children: formatCaption(textBeforeHighlight, 'Bob') }), _jsx("span", { children: `${highlightedWord}` }), _jsx("span", { style: { color: unHighlightedColor }, children: textAfterHighlight })] }));
            case 'UpToCurrentWord':
                return (_jsxs(_Fragment, { children: [_jsx("span", { children: formatCaption(`${textBeforeWithHighlight}`, 'Bob') }), _jsx("span", { style: { color: unHighlightedColor }, children: textAfterHighlight })] }));
            case 'OneWordChunks':
                return (_jsx("span", { children: highlightedWord }));
            case 'HalfSecondChunks':
                return (_jsx("span", { children: textAfterHighlight }));
            default:
                return (_jsx("span", { children: formatCaption(text, 'Bob') }));
        }
    };
    const multiLineAnimation = previewFormat.highlightingMode !== 'OneWordChunks' && previewFormat.highlightingMode !== 'HalfSecondChunks';
    const secondLineStyle = {
        color: previewFormat.highlightingMode === 'None' ? previewStyle.color : previewFormat.unHighlightedColor,
    };
    return (_jsx(Form.Item, { className: styles.previewLabel, label: txt('captionPreview'), children: _jsxs("div", { className: styles.preview, children: [_jsx("div", { children: _jsx("img", { className: styles.image, ref: previewRef, src: corgi, alt: "corgi" }) }), _jsxs("div", { style: previewStyle, className: styles.text, children: [_jsx("div", { className: clsx(styles.line, styles[previewFormat.backgroundColor]), children: createAnimatedText(txt('captionPreviewText1')) }), previewFormat.lineCount === 2 && multiLineAnimation && (_jsx("div", { className: clsx(styles.line, styles[previewFormat.backgroundColor]), style: secondLineStyle, children: _jsx("span", { children: formatCaption(txt('captionPreviewText2'), 'Jenny') }) }))] })] }) }));
};
export default CaptionPreview;
