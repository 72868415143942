import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { txt } from 'libs/i18n';
import { subtitlesDefaults } from 'api/settings/user-settings';
import { Select, Form, Tooltip, Flex, Popconfirm, Switch, Radio, Collapse, } from 'antd';
import { useEffect, useState } from 'react';
import { useSession } from 'components/Authenticated';
import IconButton from 'components/IconButton';
import { DeleteOutlined, InfoCircleOutlined } from '@ant-design/icons';
import RangeInput from '../RangeInput';
import styles from './style.module.less';
export const SUBTITLE_LENGTH = { min: 15, max: 60 };
const ConvertToSubsContent = ({ form, isCaptionMode, selectedTemplate, onSelectTemplate, }) => {
    const { session, updateTeamSettings } = useSession();
    const [templates, setTemplates] = useState([]);
    useEffect(() => {
        const fetchTeamTemplates = () => {
            const { subtitlesTemplates } = session.teamSettings;
            setTemplates([...subtitlesTemplates].map((template) => (!template.isEditable && template.name === 'default'
                ? Object.assign(Object.assign({}, template), { name: txt('recommendedSettings') }) : template)));
        };
        void fetchTeamTemplates();
    }, []);
    const selectTemplate = (templateName) => {
        const chosenTemplate = templates.find((template) => template.name === templateName);
        if (chosenTemplate !== undefined) {
            onSelectTemplate(chosenTemplate);
            form.setFieldsValue(chosenTemplate.settings);
        }
    };
    const deleteTemplate = (templateName) => {
        const filteredTemplates = templates.filter((template) => template.name !== templateName);
        setTemplates(filteredTemplates);
        updateTeamSettings({ subtitlesTemplates: filteredTemplates });
        const defaultTemplate = filteredTemplates.find((template) => template.settings.templateName === 'default');
        if (defaultTemplate !== undefined) {
            onSelectTemplate(Object.assign(Object.assign({}, defaultTemplate), { name: '' }));
        }
    };
    return (_jsxs(_Fragment, { children: [_jsx(Form.Item, { label: txt('useTemplate'), children: _jsx(Select, { showSearch: true, className: styles.templateSelect, value: selectedTemplate.name, onChange: selectTemplate, optionLabelProp: "label", children: templates.map((template) => (_jsx(Select.Option, { value: template.name, children: _jsxs(Flex, { justify: "space-between", children: [template.name, template.isEditable && (_jsx(Popconfirm, { trigger: "click", onPopupClick: (e) => e.stopPropagation(), title: txt('deleteTemplateTeam'), onConfirm: () => deleteTemplate(template.name), children: _jsx(Tooltip, { title: txt('deleteTemplate'), children: _jsx("button", { type: "button", onClick: (e) => e.stopPropagation(), children: _jsx(IconButton, { children: _jsx(DeleteOutlined, {}) }) }) }) }))] }) }, template.name))) }) }), _jsx(RangeInput, { label: txt('lineLength'), required: true, name: "maxLineLength", description: txt('maxLineLengthDescription'), min: SUBTITLE_LENGTH.min, max: SUBTITLE_LENGTH.max, step: 1, unit: txt('charactersPerLine'), form: form, disabled: isCaptionMode, restoreValue: subtitlesDefaults.maxLineLength }), _jsx(Form.Item, { name: "subtitlerMaxLineCount", required: true, label: txt('subtitlerMaxLineCount'), tooltip: isCaptionMode ? {
                    title: txt('changeDisabled'),
                    icon: _jsx(InfoCircleOutlined, {}),
                } : null, children: _jsxs(Radio.Group, { disabled: isCaptionMode, children: [_jsx(Radio, { value: 1, children: `1 ${txt('line')}` }), _jsx(Radio, { value: 2, children: `2 ${txt('twoLines')}` })] }) }), _jsx(Collapse, { ghost: true, items: [{
                        key: '1',
                        label: txt('advancedSettings'),
                        forceRender: true,
                        children: (_jsx(Form.Item, { label: txt('enablePresegmentation'), tooltip: {
                                title: isCaptionMode ? txt('changeDisabled') : txt('enablePresegmentationTooltip'),
                                icon: _jsx(InfoCircleOutlined, {}),
                            }, name: "enablePresegmentation", valuePropName: "checked", children: _jsx(Switch, { size: "small", disabled: isCaptionMode }) })),
                    }] })] }));
};
export default ConvertToSubsContent;
