import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { App, Button, Descriptions, Flex, Result, Space, Spin, Tooltip, } from 'antd';
import { useLocation } from 'react-router-dom';
import { txt } from 'libs/i18n';
import dayjs from 'dayjs';
import { useSession } from 'components/Authenticated';
import { activateSubscription, cancelSubscription, } from 'api/team-api';
import { getDurationStringFromIso } from 'libs/duration';
import styles from './style.module.less';
import RemainingCredit from '../RemainingCredit';
const AzurePlans = ({ subscriptions, onRefetchSubscriptions, totalCredit, onRefetchCredit, }) => {
    const { session } = useSession();
    const { message } = App.useApp();
    const location = useLocation();
    const [loading, setLoading] = useState(false);
    const pendingSubscriptions = subscriptions.filter((sub) => sub.subscriptionStatus === 'PendingActivation');
    const canceledSubscriptions = subscriptions.filter((sub) => sub.subscriptionStatus === 'Unsubscribed');
    const activeSubscription = subscriptions.find((sub) => sub.isActive);
    const newestPendingSubscription = pendingSubscriptions.sort((a, b) => b.id - a.id)[0];
    const newestCanceledSubscription = canceledSubscriptions.sort((a, b) => b.id - a.id)[0];
    const activationPending = (activeSubscription === null || activeSubscription === void 0 ? void 0 : activeSubscription.subscriptionStatus) === 'PendingActivation';
    useEffect(() => {
        // NOTE: There is a redirect from Azure Marketplace directly
        // to this subpage when subscription is purchased.
        void onRefetchCredit();
    }, []);
    const handleActivate = async (subscriptionId) => {
        setLoading(true);
        const result = await activateSubscription(session.connection, subscriptionId);
        if (result.isSuccess()) {
            if (result.get().activated === true) {
                void message.success(txt('activationSuccess'));
            }
            else {
                void message.info(txt('activationPending'));
            }
        }
        if (result.isFail()) {
            if (result.err() === 'failed') {
                void message.error(txt('activationFailed'));
            }
            else if (result.err() === 'deactivate') {
                void message.error(txt('deactivateFirst'), 10);
            }
            else {
                void message.error(txt('unexpectedError'));
            }
        }
        setLoading(false);
        onRefetchSubscriptions();
    };
    const handleCancel = async (subscriptionId) => {
        setLoading(true);
        const result = await cancelSubscription(session.connection, subscriptionId);
        if (result.isSuccess()) {
            if (result.get().canceled === true) {
                void message.success(txt('cancellationSuccess'));
            }
            else {
                void message.info(txt('cancellationPending'));
            }
        }
        if (result.isFail()) {
            if (result.err() === 'failed') {
                void message.error(txt('cancellationFailed'));
            }
            else {
                void message.error(txt('unexpectedError'));
            }
        }
        setLoading(false);
        onRefetchSubscriptions();
    };
    const goToAccountButton = (_jsx(Button, { type: "primary", onClick: () => window.open('/account/credit', '_self'), children: txt('goToAccount') }, "console"));
    const createSubscriptionItems = (subscription) => {
        if (subscription.subscriptionStatus === 'PendingActivation') {
            return [
                {
                    key: 1,
                    label: txt('plan'),
                    span: 3,
                    children: subscription.name,
                },
                {
                    key: 2,
                    label: txt('planForPeriod'),
                    span: 3,
                    children: getDurationStringFromIso(subscription.period),
                },
            ];
        }
        return [
            {
                key: 1,
                label: txt('plan'),
                span: 3,
                children: subscription.name,
            },
            {
                key: 2,
                label: txt('planForPeriod'),
                span: 3,
                children: getDurationStringFromIso(subscription.period),
            },
            {
                key: 3,
                label: txt('from'),
                span: 2,
                children: dayjs(subscription.startDate).format('DD/MM/YYYY'),
            },
            {
                key: 4,
                label: txt('to'),
                children: dayjs(subscription.endDate).format('DD/MM/YYYY'),
            },
            {
                key: 5,
                label: txt('status'),
                children: subscription.subscriptionStatus,
            },
        ];
    };
    if (location.search.includes('newSubscription') && pendingSubscriptions.length === 0) {
        return (_jsx(Result, { status: "success", title: txt('thankyou'), subTitle: txt('subscriptionActive'), extra: [
                goToAccountButton,
            ] }));
    }
    if (location.search.includes('failed')) {
        return (_jsx(Result, { status: "error", title: txt('subscriptionFailed'), subTitle: (txt('tryAgainOrContact')), extra: [
                goToAccountButton,
            ] }));
    }
    return (_jsxs("div", { className: styles.layout, children: [_jsx(RemainingCredit, { totalCredit: totalCredit }), activeSubscription !== undefined ? (_jsx(Descriptions, { title: txt('activeSubscription'), size: "small", items: createSubscriptionItems(activeSubscription), className: styles.descriptions, bordered: true, extra: (_jsxs(Flex, { gap: "middle", children: [_jsx(Button, { type: "primary", size: "small", onClick: () => (activationPending
                                ? handleActivate(activeSubscription.id)
                                : handleCancel(activeSubscription.id)), disabled: loading, children: _jsxs(Space, { children: [activationPending ? txt('activateSubscription') : txt('cancelPlan'), loading && _jsx(Spin, {})] }) }), _jsx(Button, { type: "primary", size: "small", onClick: () => window.open(activeSubscription.portalUrl, '_blank'), children: txt('customerPortal') })] })) })) : (canceledSubscriptions.length > 0 && (_jsx(Descriptions, { title: txt('canceledSubscription'), size: "small", items: createSubscriptionItems(newestCanceledSubscription), className: styles.descriptions, bordered: true, extra: (_jsx(Button, { type: "primary", size: "small", onClick: () => window.open(newestCanceledSubscription.portalUrl, '_blank'), children: txt('customerPortal') })) }))), pendingSubscriptions.length > 0 && (_jsx(Descriptions, { title: txt('pendingSubscription'), size: "small", items: createSubscriptionItems(newestPendingSubscription), className: styles.descriptions, bordered: true, extra: (_jsxs(Flex, { gap: "middle", children: [_jsx(Tooltip, { title: activeSubscription !== undefined ? txt('deactivateFirst') : false, children: _jsx(Button, { type: "primary", size: "small", onClick: () => handleActivate(newestPendingSubscription.id), disabled: loading || activeSubscription !== undefined, children: _jsxs(Space, { children: [txt('activateSubscription'), loading && _jsx(Spin, {})] }) }) }), _jsx(Button, { type: "primary", size: "small", onClick: () => window.open(newestPendingSubscription.portalUrl, '_blank'), children: txt('customerPortal') })] })) }))] }));
};
export default AzurePlans;
