export const getRectFromElement = (element) => {
    const rect = element.getBoundingClientRect();
    return {
        display: 'block',
        position: 'fixed',
        left: rect.left,
        top: rect.top,
        height: rect.height,
        width: rect.width,
    };
};
export const getRectElementPositionAsync = (nodes) => {
    const bounds = [];
    const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => bounds.push({
            display: 'block',
            position: 'fixed',
            left: entry.boundingClientRect.left,
            top: entry.boundingClientRect.top,
            height: entry.boundingClientRect.height,
            width: entry.boundingClientRect.width,
        }));
        // NOTE: Disconnect the observer to stop from running in the background
        observer.disconnect();
    });
    [...nodes].forEach((node) => observer.observe(node));
    return bounds;
};
export const centerHorizontally = (bounds, width) => {
    const adjustedWidth = Math.min(bounds.width, width);
    return {
        display: 'block',
        position: 'fixed',
        height: bounds.height,
        width: adjustedWidth,
        top: bounds.top,
        left: bounds.left + (bounds.width - adjustedWidth) / 2,
    };
};
export const splitAtFixedPositionHorizontally = (bounds, splitPosition) => {
    const adjustedSplitPosition = Math.min(bounds.width, splitPosition);
    return {
        left: {
            display: 'block',
            position: 'fixed',
            left: bounds.left,
            width: adjustedSplitPosition,
            height: bounds.height,
            top: bounds.top,
        },
        right: {
            display: 'block',
            position: 'fixed',
            left: bounds.left + adjustedSplitPosition,
            width: bounds.width - adjustedSplitPosition,
            height: bounds.height,
            top: bounds.top,
        },
    };
};
export const splitAtFixedPositionVertically = (bounds, splitPosition) => {
    const adjustedSplitPosition = Math.min(bounds.height, splitPosition);
    return {
        top: {
            display: 'block',
            position: 'fixed',
            left: bounds.left,
            width: bounds.width,
            height: adjustedSplitPosition,
            top: bounds.top,
        },
        bottom: {
            display: 'block',
            position: 'fixed',
            left: bounds.left,
            width: bounds.width,
            height: bounds.height - adjustedSplitPosition,
            top: bounds.top + adjustedSplitPosition,
        },
    };
};
