import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { txt } from 'libs/i18n';
import { createTutorial } from 'components/Tutorial/';
import TutorialTooltip from 'components/Tutorial/TutorialTooltip';
const SUB_MODE_TUTORIAL_STEP_COUNT = 6;
export const getSubModeTutorialSteps = () => ([
    {
        target: '.sub-mode-tutorial-step-split',
        disableBeacon: true,
        content: (_jsxs(TutorialTooltip, { stepCount: SUB_MODE_TUTORIAL_STEP_COUNT, stepIndex: 0, children: [_jsx("p", { className: "tutorial__warning", children: txt('subModeTutorialSubEnd') }), _jsx("p", { className: "tutorial__warning tutorial__warning--red", children: txt('subModeTutorialRedWarning') }), _jsx("p", { className: "tutorial__tip", children: txt('subModeTutorialSplitStep') })] })),
    },
    {
        target: '.sub-mode-tutorial-step-wave',
        disableBeacon: true,
        placement: 'right-start',
        styles: {
            tooltip: {
                width: '400px',
            },
        },
        content: (_jsxs(_Fragment, { children: [_jsxs(TutorialTooltip, { stepCount: SUB_MODE_TUTORIAL_STEP_COUNT, stepIndex: 1, children: [_jsx("p", { className: "tutorial__warning tutorial__warning--yellow", children: txt('subModeTutorialYellowWarning') }), _jsx("p", { className: "tutorial__warning tutorial__warning--light-yellow", children: txt('subModeTutorialLightYellowWarning') }), _jsx("p", { children: txt('subModeTutorialWaveStep') })] }), _jsxs("p", { children: [_jsx("strong", { children: "Tip: " }), txt('subModeTutorialWaveTip')] })] })),
    },
    {
        target: '.sub-mode-tutorial-step-zoom',
        disableBeacon: true,
        content: (_jsx(TutorialTooltip, { stepCount: SUB_MODE_TUTORIAL_STEP_COUNT, stepIndex: 2, children: txt('subModeTutorialZoomStep') })),
    },
    {
        target: '.sub-mode-tutorial-step-delete',
        disableBeacon: true,
        content: (_jsx(TutorialTooltip, { stepCount: SUB_MODE_TUTORIAL_STEP_COUNT, stepIndex: 3, children: txt('subModeTutorialDeleteStep') })),
    },
    {
        target: '.sub-mode-tutorial-step-settings',
        disableBeacon: true,
        content: (_jsx(TutorialTooltip, { stepCount: SUB_MODE_TUTORIAL_STEP_COUNT, stepIndex: 4, children: txt('subModeTutorialSettingsStep') })),
    },
    {
        target: '.sub-mode-tutorial-step-choose-settings',
        disableBeacon: true,
        styles: {
            tooltip: {
                zIndex: '1000',
            },
        },
        content: (_jsx(TutorialTooltip, { stepCount: SUB_MODE_TUTORIAL_STEP_COUNT, stepIndex: 4, children: txt('subModeTutorialChooseSettingsStep') })),
    },
    {
        target: '.sub-mode-tutorial-step-save-settings',
        disableBeacon: true,
        content: (_jsxs(_Fragment, { children: [_jsx(TutorialTooltip, { stepCount: SUB_MODE_TUTORIAL_STEP_COUNT, stepIndex: 5, children: txt('subModeTutorialSaveSettingsStep') }), _jsx("p", { className: "tutorial__tip", children: txt('subModeTutorialSaveSettingsTip') })] })),
    },
]);
export const [useSubModeTutorial, SubModeTutorial, SubModeTutorialContext] = createTutorial('subMode');
