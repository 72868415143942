import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Alert, Button, Form, Input, Tooltip, } from 'antd';
import { txt } from 'libs/i18n';
import dayjs from 'dayjs';
import { InfoCircleOutlined } from '@ant-design/icons';
import styles from './style.module.less';
export const getTimeInSeconds = (time) => {
    const [hours, minutes, seconds] = time.split(':').map(Number);
    const totalSeconds = dayjs.duration({
        hours,
        minutes,
        seconds,
    }).asSeconds();
    return Number(totalSeconds.toFixed(3));
};
export const formatTimeToISOString = (seconds) => {
    // NOTE: rounding is necessary, because oherwise the formatted time
    // could be one millisecond lower because of javascript arithmetic error.
    const date = new Date(Math.round(seconds * 1000));
    return date.toISOString().slice(11, 23);
};
export const validateForm = async (begin, end, durationInSeconds) => {
    const regex = /^\d{2}:\d{2}:\d{2}\.\d{3}$/;
    if (!regex.test(begin) || !regex.test(end)) {
        return Promise.reject(new Error(txt('timeRangeFormat')));
    }
    const beginInSeconds = getTimeInSeconds(begin);
    const endInSeconds = getTimeInSeconds(end);
    if (beginInSeconds >= endInSeconds) {
        return Promise.reject(new Error(txt('beginningBeforeEnd')));
    }
    if (beginInSeconds > durationInSeconds
        || endInSeconds > durationInSeconds) {
        return Promise.reject(new Error(txt('notGreaterThanRecording')));
    }
    return Promise.resolve();
};
const TimerangeSelectionForm = ({ buttonText, loading = false, durationInSeconds, alertText, wholeLength, onFullLengthSelected, children, }) => {
    const getAlert = () => {
        if (children !== undefined) {
            return children;
        }
        if (alertText !== undefined) {
            return (_jsx(Alert, { showIcon: true, message: alertText, type: "info", className: styles.alert }));
        }
        return null;
    };
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: styles.inputs, children: [_jsx(Form.Item, { name: "begin", rules: [
                            {
                                required: true,
                                whitespace: true,
                                message: txt('fillInSubtitlesStart'),
                            },
                            ({ getFieldValue }) => ({
                                async validator(_, begin) {
                                    const end = getFieldValue('end');
                                    return validateForm(begin, end, durationInSeconds);
                                },
                            }),
                        ], children: _jsx(Input, { placeholder: "00:00:00.000", maxLength: 12, allowClear: true }) }), _jsx("span", { className: styles.hyphen, children: "-" }), _jsx(Form.Item, { name: "end", rules: [
                            {
                                required: true,
                                whitespace: true,
                                message: txt('fillInSubtitlesEnd'),
                            },
                            ({ getFieldValue }) => ({
                                async validator(_, end) {
                                    const begin = getFieldValue('begin');
                                    return validateForm(begin, end, durationInSeconds);
                                },
                            }),
                        ], children: _jsx(Input, { placeholder: "00:00:00.000", maxLength: 12, allowClear: true }) }), _jsx(Button, { disabled: wholeLength, className: styles.fullLengthButton, size: "small", onClick: onFullLengthSelected, children: txt('fullLength') }), _jsx(Tooltip, { title: txt('burnTimeInfo'), children: _jsx(InfoCircleOutlined, {}) })] }), _jsxs("div", { className: styles.footer, children: [getAlert(), _jsx(Button, { type: "primary", htmlType: "submit", disabled: loading, loading: loading, children: buttonText })] })] }));
};
export default TimerangeSelectionForm;
