import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Popconfirm, Tooltip, Flex, Alert, Typography, } from 'antd';
import { PlusCircleOutlined, SaveOutlined, UndoOutlined, } from '@ant-design/icons';
import { txt } from 'libs/i18n';
import IconButton from 'components/IconButton';
import { useSession } from 'components/Authenticated';
import styles from './style.module.less';
import NewTemplateButton from '../NewTemplateButton';
const { Text } = Typography;
const TemplateSection = ({ currentTemplate, onCurrentTemplateUpdated, form, }) => {
    const { session, updateTeamSettings } = useSession();
    const templates = session.teamSettings.subtitlesTemplates;
    const resetTemplate = () => {
        form.setFieldsValue(currentTemplate.settings);
        onCurrentTemplateUpdated(Object.assign(Object.assign({}, currentTemplate), { edited: false }));
    };
    const updateTemplate = () => {
        const updatedTemplates = templates.map((template) => (template.name === currentTemplate.name
            ? Object.assign(Object.assign({}, template), { name: currentTemplate.name, settings: form.getFieldsValue() }) : template));
        updateTeamSettings({ subtitlesTemplates: updatedTemplates });
        onCurrentTemplateUpdated(Object.assign(Object.assign({}, currentTemplate), { edited: false }));
    };
    const handleTemplateCreated = (name) => {
        onCurrentTemplateUpdated({
            name, settings: form.getFieldsValue(), isEditable: true, edited: false,
        });
    };
    return (_jsxs(Flex, { align: "center", children: [_jsxs(Flex, { gap: "small", align: "center", children: [currentTemplate.name !== '' ? (_jsxs(_Fragment, { children: [_jsx("span", { children: `${txt('usedTemplate')}: ` }), _jsx("span", { children: currentTemplate.name })] })) : (_jsx(Text, { italic: true, children: txt('noTemplate') })), currentTemplate.edited && currentTemplate.name !== ''
                        && _jsx(Alert, { className: styles.templateAlert, showIcon: true, type: "warning", message: txt('editedTemplate') })] }), _jsx(NewTemplateButton, { newTemplateSettings: form.getFieldsValue(), onTempleCreated: handleTemplateCreated, children: _jsx(Tooltip, { placement: "bottom", title: txt('saveAsNewTemplate'), children: _jsx("div", { children: _jsx(IconButton, { children: _jsx(PlusCircleOutlined, {}) }) }) }) }), currentTemplate.edited && currentTemplate.name !== '' && (_jsxs("div", { className: styles.templateControls, children: [_jsx(Tooltip, { placement: "bottom", title: txt('resetChanges'), children: _jsx("div", { children: _jsx(IconButton, { onClick: resetTemplate, children: _jsx(UndoOutlined, {}) }) }) }), currentTemplate.isEditable && (_jsx(Popconfirm, { title: txt('updateTemplateTeam'), okText: txt('yesUpdate'), okType: "primary", okButtonProps: { danger: true }, onConfirm: updateTemplate, cancelText: txt('cancel'), children: _jsx(Tooltip, { title: txt('overwriteTemplate'), placement: "bottom", children: _jsx("div", { children: _jsx(IconButton, { children: _jsx(SaveOutlined, {}) }) }) }) }))] }))] }));
};
export default TemplateSection;
