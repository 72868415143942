import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Collapse, Flex, Space, Spin, } from 'antd';
import { txt } from 'libs/i18n';
import styles from './style.module.less';
import { separateThousands } from '../TeamPlan/LastPayment';
import CreditValidity from '../CreditValidity';
const RemainingCredit = ({ totalCredit }) => {
    const creditValidityItems = [
        {
            key: '1',
            label: txt('creditValidity'),
            children: _jsx(CreditValidity, {}),
        },
    ];
    return (_jsxs(Flex, { className: styles.remainingCredit, children: [_jsxs(Space, { align: "start", className: styles.space, children: [_jsx("span", { className: styles.label, children: `${txt('remainingTeamCredit')}:` }), _jsx("strong", { className: styles.credit, children: totalCredit !== 'loading' ? `${separateThousands(totalCredit)} min` : _jsx(Spin, {}) })] }), _jsx("div", { className: styles.creditValidity, children: _jsx(Collapse, { items: creditValidityItems }) })] }));
};
export default RemainingCredit;
