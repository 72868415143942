import { txt } from './i18n';
export const localizeTime = (count, timeRange) => {
    let number;
    if (count === 1) {
        number = 'one';
    }
    else if (count >= 2 && count <= 4) {
        number = 'few';
    }
    else {
        number = 'many';
    }
    const timeKey = `${timeRange}_${number}`;
    return txt(timeKey);
};
