import { apiV2 } from '@newtontechnologies/beey-api-js-client/endpoints';
import { json, blob, } from '@newtontechnologies/beey-api-js-client/receivers';
import { TeamSettingsSchema } from './settings/team-settings';
export const fetchCurrentTeam = async (connection) => {
    const teamData = await connection.authFetch()
        .url(apiV2.currentTeam.url())
        .receive(json())
        .fetch();
    teamData.settings = TeamSettingsSchema.sanitize(teamData.settings);
    return teamData;
};
export const fetchCurrentTeamTranscriptionPerPeriod = async (connection, dates, orderBy, ascending, skip = 0, count = 0) => {
    const params = {
        from: dates[0],
        to: dates[1],
        orderBy,
        ascending,
        skip,
        count,
    };
    return connection.authFetch()
        .url(apiV2.currentTeam.transcriptionPerPeriod.url(params))
        .receive(json())
        .fetch();
};
export const fetchCurrentTeamMembers = async (connection, skip = 0, count = 0) => connection.authFetch()
    .url(apiV2.currentTeam.members.url({ skip, count }))
    .receive(json())
    .fetch();
export const fetchMemberTranscriptionLog = async (connection, id, dates, skip = 0, count = 0) => {
    const params = {
        memberId: id,
        from: dates[0],
        to: dates[1],
        skip,
        count,
    };
    return connection.authFetch()
        .url(apiV2.currentTeam.members.id(id).transcriptionLog.url(params))
        .receive(json())
        .fetch();
};
export const exportMemberTranscriptionLog = async (connection, id, dates) => connection.authFetch()
    .url(apiV2.currentTeam.members.id(id).transcriptionLog.export.url(dates.length === 0
    ? undefined
    : {
        from: dates[0],
        to: dates[1],
    }))
    .receive(blob())
    .fetch();
export const storeCurrentTeamSettings = async (connection, settings) => connection.authFetch()
    .url(apiV2.currentTeam.settings.url())
    .postJson(settings)
    .send();
export const fetchCurrentTeamSettings = async (connection) => {
    const settings = await connection.authFetch()
        .url(apiV2.currentTeam.settings.url())
        .receive(json())
        .fetch();
    return TeamSettingsSchema.sanitize(settings);
};
export const fetchCurrentTeamDefaultTags = async (connection) => {
    const settings = await fetchCurrentTeamSettings(connection);
    return [...settings.teamTags];
};
export const fetchCurrentTeamTags = async (connection, skip, count) => connection.authFetch()
    .url(apiV2.currentTeam.projectTags.url({ skip, count }))
    .receive(json())
    .fetch();
export const addCurrentTeamTag = async (connection, key, abbreviation, color, isPinned) => connection.authFetch()
    .url(apiV2.currentTeam.projectTags.url())
    .postJson({
    key,
    abbreviation,
    color,
    isPinned,
})
    .receive(json())
    .fetch();
export const updateCurrentTeamTag = async (connection, tagId, key, abbreviation, color, isPinned) => connection.authFetch()
    .url(apiV2.currentTeam.projectTags.id(tagId).url())
    .putJson({
    key,
    abbreviation,
    color,
    isPinned,
})
    .receive(json())
    .fetch();
export const deleteCurrentTeamTag = async (connection, tagId) => connection.authFetch()
    .url(apiV2.currentTeam.projectTags.id(tagId).url())
    .delete()
    .receive(json())
    .fetch();
export const teamOwnerAbandonProjectsIn = async (connection, abandonProjects) => {
    const body = {
        Age: abandonProjects.inDays === null ? null : `${String(abandonProjects.inDays)}.00:00:00`,
    };
    return connection.authFetch()
        .url(apiV2.currentTeam.abandonProjectsIn.url())
        .postJson(body)
        .receive(json())
        .send();
};
export const fetchExpiringCredit = async (connection) => connection.authFetch()
    .url(apiV2.currentTeam.expiringCredit.url())
    .receive(json())
    .fetch();
export const fetchStripeProducts = async (connection) => connection.authFetch()
    .url(apiV2.shop.getStripeProducts.url())
    .receive(json())
    .fetch();
export const fetchTeamPlan = async (connection) => connection.authFetch()
    .url(apiV2.currentTeam.plan.url())
    .receive(json())
    .fetch();
export const fetchExportTemplates = async (connection) => connection.authFetch()
    .url(apiV2.currentTeam.exportTemplates.url())
    .receive(json())
    .fetch();
export const fetchTeamStorageStatistics = async (connection) => connection.authFetch()
    .url(apiV2.currentTeam.storageStatistics.url())
    .receive(json())
    .fetch();
export const deleteTeamMember = async (connection, userId) => connection.authFetch()
    .url(apiV2.currentTeam.members.id(userId).url())
    .delete()
    .send();
export const addTeamMember = async (connection, email, password, language) => connection.authFetch()
    .url(apiV2.currentTeam.members.url())
    .postJson({
    sendEmail: true,
    member: {
        email,
        password,
        language,
    },
})
    .receive(json())
    .fetch();
// FIXME: @EvaMach Abhorrent check, remove when implemented on BE
export const getAllowedNumberOfMembers = (lookupKey) => {
    if (lookupKey === 'beey_start') {
        return 1;
    }
    if (lookupKey === 'beey_business_monthly' || lookupKey === 'beey_business_yearly') {
        return 10;
    }
    if (lookupKey === 'beey_plus_monthly' || lookupKey === 'beey_plus_yearly') {
        return 5;
    }
    return 0;
};
export const transferOwnership = async (connection, newOwnerId) => {
    const body = { NewOwnerId: newOwnerId };
    return (connection.authFetch()
        .url(apiV2.currentTeam.transferOwnership.url())
        .putJson(body)
        .send());
};
export const exportLexiconRules = async (connection, language) => connection.authFetch()
    .url(apiV2.currentTeam.lexicon.export.url({ language }))
    .receive(blob())
    .fetch();
export const fetchSubscriptions = async (connection) => {
    return connection.authFetch()
        .url(apiV2.currentTeam.subscriptions.url())
        .receive(json())
        .fetch();
};
export const activateSubscription = async (connection, subscriptionId) => connection.authFetch()
    .url(apiV2.currentTeam.subscriptions.id(subscriptionId).activate.url())
    .receive(json()
    .catchHttpError((response) => {
    if (response.status === 422 || response.status === 503) {
        return 'failed';
    }
    if (response.status === 400) {
        return 'deactivate';
    }
    return 'unexpected';
}))
    .fetchStrict();
export const cancelSubscription = async (connection, subscriptionId) => connection.authFetch()
    .url(apiV2.currentTeam.subscriptions.id(subscriptionId).cancel.url())
    .post()
    .receive(json()
    .catchHttpError((response) => (response.status === 422 || response.status === 503 ? 'failed' : 'unexpected')))
    .fetchStrict();
export const changeSubscription = async (connection, subscriptionId, planId) => connection.authFetch()
    .url(apiV2.currentTeam.subscriptions.id(subscriptionId).changePlan.url())
    .postJson({
    PlanId: planId,
})
    .send();
export const fetchAvailablePlans = async (connection) => connection.authFetch()
    .url(apiV2.currentTeam.subscriptions.availablePlans.url())
    .receive(json())
    .fetch();
