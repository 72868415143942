import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect } from 'react';
import { InputNumber, Form, Switch, } from 'antd';
import clsx from 'clsx';
import { InfoCircleOutlined } from '@ant-design/icons';
import { txt } from 'libs/i18n';
import styles from './style.module.less';
const RangeInput = ({ name, label, description, min, max, step, unit, validator, required = false, className, disabled = false, switchable = false, form, restoreValue, disabledValue, onValuesChange, dependencies, }) => {
    const [inputDisabled, setInputDisabled] = useState(switchable && form.getFieldValue(name) === disabledValue);
    const [lastValue, setLastValue] = useState(restoreValue);
    useEffect(() => {
        setInputDisabled(form.getFieldValue(name) === disabledValue);
    }, [form.getFieldValue(name)]);
    const handleValuesChange = async () => {
        await form.validateFields([name]);
        onValuesChange === null || onValuesChange === void 0 ? void 0 : onValuesChange();
    };
    const rules = [];
    if (!disabled) {
        rules.push(() => ({
            validator(_, value) {
                if (value === disabledValue) {
                    return Promise.resolve();
                }
                if (value < min || value > max) {
                    return Promise.reject(new Error(`${txt('range')} ${min} - ${max}`));
                }
                return Promise.resolve();
            },
        }));
    }
    if (validator !== undefined) {
        rules.push(validator);
    }
    return (_jsxs("div", { className: clsx({ [styles.switchableInput]: switchable }, className), children: [switchable && (_jsx(Switch, { size: "small", checked: form.getFieldValue(name) !== disabledValue, onChange: (checked) => {
                    setInputDisabled(!checked);
                    if (!checked) {
                        setLastValue(form.getFieldValue(name));
                        form.setFieldsValue({ [name]: disabledValue });
                    }
                    else {
                        form.setFieldsValue({ [name]: lastValue });
                    }
                    void handleValuesChange();
                } })), _jsxs(Form.Item, { label: label, labelCol: switchable ? { span: 11 } : undefined, required: required, tooltip: {
                    title: disabled ? txt('changeDisabled') : `${description} ${txt('range')}\u00A0${min}\u00A0-\u00A0${max}`,
                    icon: _jsx(InfoCircleOutlined, {}),
                }, children: [_jsx(Form.Item, { name: name, dependencies: dependencies, noStyle: true, rules: rules, children: _jsx(InputNumber, { disabled: disabled || inputDisabled, type: "number", step: step, onChange: (value) => {
                                if (value === disabledValue) {
                                    setInputDisabled(true);
                                }
                                void handleValuesChange();
                            } }) }), ' ', unit] })] }));
};
export default RangeInput;
