import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Alert, ColorPicker, Form, Select, } from 'antd';
import { txt } from 'libs/i18n';
const { Option } = Select;
const AnimationTab = ({ highlightingMode }) => {
    const highlightingOptions = ['None', 'CurrentWord', 'UpToCurrentWord', 'OneWordChunks', 'HalfSecondChunks'];
    return (_jsxs("div", { children: [_jsx(Form.Item, { label: `${txt('highlightingMode')}:`, name: "highlightingMode", children: _jsx(Select, { children: highlightingOptions.map((option) => (_jsx(Option, { value: option, children: option === 'None' ? txt('noHighlight') : txt(`${option.toLowerCase()}`) }, option))) }) }), highlightingMode === 'CurrentWord' || highlightingMode === 'UpToCurrentWord' ? (_jsx(Form.Item, { label: `${txt('unhighlightedColor')}:`, name: "unHighlightedColor", getValueFromEvent: (clr) => {
                    return clr.toHexString();
                }, children: _jsx(ColorPicker, { showText: true }) })) : null, _jsx(Alert, { showIcon: true, type: "info", message: txt('burnOnly') })] }));
};
export default AnimationTab;
